import dayjs, { Dayjs } from 'dayjs';
import { uniq } from 'lodash-es';

import type { MaybeDate } from '@/utils/format-utils';

export const timeZoneFormatDateTime = 'YYYY-MM-DDTHH:mm:ss';
export const timeZoneFormatDate = 'YYYY-MM-DD';

export function formatDate(date: string | Date | null | undefined, locale = 'en') {
  return date && dayjs(date).isValid() ? dayjs(date).locale(locale).format('ll') : '';
}

export function formatDateTime(date: string | Date | null | undefined, locale = 'en') {
  return date && dayjs(date).isValid() ? dayjs(date).locale(locale).format('ll, LT') : '';
}

export function formatISODate(date: string | Date | Dayjs | null | undefined): string {
  return date && dayjs(date).isValid() ? dayjs(date).format('YYYY-MM-DD') : '';
}

/**
 * Returns a Dayjs object with the timezone applied to it.
 * Use `formatTimeZoneDate` to get a timezone-less string from it which can be used for third party libs (like the calendar)
 *
 * See: https://day.js.org/docs/en/plugin/timezone
 *
 * @param date
 * @param timezone
 */
export function toTimeZoneDate(date: Date | Dayjs | string = new Date(), timezone?: string): Dayjs {
  if (dayjs.isDayjs(date)) {
    return date.tz(timezone);
  } else {
    return dayjs.utc(date).tz(timezone);
  }
}

export function toTimeZoneFormat(date: Date | Dayjs | string = new Date(), timezone?: string): string {
  return formatTimeZoneDate(toTimeZoneDate(date, timezone));
}

/**
 * Converts the given date to a string without time zone information
 * @param date
 */
export function formatTimeZoneDate(date: Dayjs): string {
  return date.format(timeZoneFormatDate);
}

/**
 * Converts the given date to a string without time zone information
 * @param date
 */
export function formatTimeZoneDateTime(date: Dayjs): string {
  return date.format(timeZoneFormatDateTime);
}

export function fromDate(dateObject: string, tz?: string) {
  const date = dayjs.tz(dateObject, tz);
  const formatFns = {
    datetime: formatTimeZoneDateTime,
    date: formatTimeZoneDate,
  };

  return {
    to(timezone: string, format: 'datetime' | 'date' = 'datetime'): string {
      return formatFns[format](date.tz(timezone));
    },
    toLocal(format: 'datetime' | 'date' = 'datetime') {
      return formatFns[format](date.tz(dayjs.tz.guess()));
    },
  };
}

export function toDateParts(dateString: string): { month: number; year: number; day: number } | null {
  if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString || '')) {
    return null;
  }

  const split = (dateString || '').split('-').map((item) => +item);

  if (split.length !== 3) {
    return null;
  }

  return {
    day: split[2],
    month: split[1],
    year: split[0],
  };
}

export function toMiddayDate(dateString: string): Date | null {
  const parts = toDateParts(dateString);

  if (!parts) {
    return null;
  }

  return new Date(parts.year, parts.month - 1, parts.day, 12, 0, 0, 0);
}

export function setToMidday(date: Date | string) {
  const d = new Date(date);
  d.setHours(12);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function formatDateRange(from: MaybeDate, to?: MaybeDate, locale = 'en'): string {
  if (!from && !to) {
    return '';
  }

  const dates = [from, to].filter((date) => !!date).map((d) => formatDate(d, locale));
  return uniq(dates).join(' - ');
}

export function getMostPastDate<T extends string | Date>(...dates: T[]): T {
  return dates
    .filter((d) => d != null)
    .reduce((oldest, current) => {
      if (dayjs(oldest).isSameOrBefore(current)) {
        return oldest;
      } else {
        return current;
      }
    }, dates[0]);
}

export function getMostFutureDate<T extends string | Date>(...dates: T[]): T {
  {
    return dates
      .filter((d) => d != null)
      .reduce((youngest, current) => {
        if (dayjs(youngest).isSameOrAfter(current)) {
          return youngest;
        } else {
          return current;
        }
      }, dates[0]);
  }
}
