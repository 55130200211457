<template>
  <UIModal
    :model-value="showConsent"
    persistent
    data-test-id="cookie-consent-modal"
  >
    <template #header>
      <div class="flex justify-end">
        <BaseLanguageSwitcher />
      </div>
    </template>
    <h2 class="flex items-center gap-4 font-semibold">
      <UIIcon>
        <MaterialSymbolsCookieOutlineRounded />
      </UIIcon>
      {{ $t('cookie.title') }}
    </h2>

    <form @submit.prevent="onSubmit">
      <div
        class="mb-2 mt-5 flex items-center justify-between gap-3"
        data-test-id="necessary-cookie-switch"
      >
        <label class="flex items-center gap-3">
          <UICheckbox
            v-model="necessary"
            disabled
          />
          <span class="text-min font-medium">
            {{ $t('cookie.necessary') }}
          </span>
        </label>
      </div>

      <div
        class="mb-4 flex items-center justify-between gap-3"
        data-test-id="marketing-cookie-switch"
      >
        <label class="flex items-center gap-3">
          <UICheckbox v-model="marketing" />

          <span class="text-min font-medium">
            {{ $t('cookie.marketing') }}
          </span>
        </label>
      </div>

      <i18n-t
        v-if="locationStore.cookiePolicyUrl"
        keypath="cookie.description"
        scope="global"
        tag="p"
        class="my-4"
      >
        <template #link>
          <UIExternalLink :to="locationStore.cookiePolicyUrl">{{ $t('cookie.linkText') }}</UIExternalLink>
        </template>
      </i18n-t>

      <div class="mt-6 flex gap-4 text-center">
        <UIButton
          data-test-id="submit-button"
          type="button"
          variant="secondary"
          responsive
          @click="handleSaveSelected"
          >{{ $t('cookie.saveSelected') }}
        </UIButton>
        <UIButton
          data-test-id="submit-button"
          type="submit"
          responsive
          >{{ $t('cookie.acceptAll') }}
        </UIButton>
      </div>
    </form>
  </UIModal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import type { Translation as I18nT } from 'vue-i18n';

import BaseLanguageSwitcher from '@/modules/base/components/BaseLanguageSwitcher.vue';
import { useCookieConsent } from '@/modules/cookie/composables/use-cookie-consent';
import UICheckbox from '@/modules/form/components/UICheckbox.vue';
import UIButton from '@/modules/ui/components/UIButton.vue';
import UIExternalLink from '@/modules/ui/components/UIExternalLink.vue';
import UIIcon from '@/modules/ui/components/UIIcon.vue';
import UIModal from '@/modules/ui/components/UIModal.vue';
import { useAppStore } from '@/stores/app-store';

const locationStore = useAppStore();
const { hasSeenCookieConsent, setCookies } = useCookieConsent();
const necessary = ref(true);
const marketing = ref(false);

const showConsent = computed(() => !hasSeenCookieConsent.value);

function handleSaveSelected() {
  setCookies({
    necessary: necessary.value,
    marketing: marketing.value,
  });
}

function onSubmit() {
  setCookies({
    necessary: true,
    marketing: true,
  });
}
</script>
