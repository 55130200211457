export type ITenantAppearanceConfig = Components.Schemas.AppearanceConfig;
export type IAppearanceConfigFontVariants = Components.Schemas.AppearanceConfigFontVariants;

export const appearanceColors = [
  'primary',
  'secondary',
  'gray-100',
  'gray-200',
  'gray-500',
  'gray-700',
  'link',
  'danger',
  'success',
  'white',
  'black',
  'text',
  'disabled',
  'darkDisabled',
  'header-background',
  'header-text',
  'background',
] as const;

export type IAppearanceColor = (typeof appearanceColors)[number];
export type IAppearanceHexColors = Record<IAppearanceColor, string>;
export type IAppearanceRGBColors = Record<IAppearanceColor, [number, number, number]>;
