import type { NavigationGuardNext, NavigationGuardWithThis, RouteLocationNormalized } from 'vue-router';

export function routeHasGuard(to: RouteLocationNormalized, guardName: string) {
  return (to.meta?.middleware as string[])?.includes(guardName);
}

export function applyGuard<T = unknown>(
  name: string,
  guard: (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => ReturnType<NavigationGuardWithThis<T>>,
): NavigationGuardWithThis<T> {
  return function (to, from, next) {
    if (routeHasGuard(to, name)) {
      return guard(to, from, next);
    } else {
      return next();
    }
  };
}
