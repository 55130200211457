import { computed, type ComputedRef, type MaybeRef, ref } from 'vue';

import type { IApiErrorDetail } from '@/types/api';
import type { Maybe } from '@/types/utility-types';
import { getApiError, isApiError } from '@/utils/error-utils';

export interface IUseApiErrorParserReturnType {
  isApiError: ComputedRef<boolean>;

  /**
   * Matches if the root code matches the given string or regex
   */
  isErrorType(type: string | RegExp): boolean;

  /**
   * Returns true if any of the detail entries contains a code that matches the given string or regex
   */
  someErrorDetailsContains(type: string | RegExp): boolean;

  /**
   * Returns true if any code property inside the object (in the root or in the details) contains the given string or regex
   */
  anyErrorCodeContains(type: string | RegExp): boolean;

  /**
   * Returns the error detail objects
   */
  errorDetails: ComputedRef<IApiErrorDetail[] | undefined>;

  /**
   * Returns the error detail codes as array
   */
  errorDetailCodes: ComputedRef<string[] | undefined>;

  /**
   * Returns the root error code
   */
  errorType: ComputedRef<string | undefined>;
}

export function useApiErrorParser(error?: MaybeRef<Maybe<unknown>>): IUseApiErrorParserReturnType {
  const givenError = ref(error);

  const errorRef = computed(() => {
    return getApiError(givenError.value);
  });
  const errorType = computed(() => (isApiError(errorRef.value) ? errorRef.value.error?.code : undefined));
  const errorDetails = computed(() => (isApiError(errorRef.value) ? errorRef.value.error?.details : undefined));
  const errorDetailCodes = computed(() => errorDetails.value?.map((detail) => detail.code));

  function isErrorType(type: string | RegExp): boolean {
    if (type instanceof RegExp) {
      return !!errorType.value?.match(type);
    }

    return errorType.value === type;
  }

  function someErrorDetailsContains(type: string | RegExp) {
    if (type instanceof RegExp) {
      return !!errorDetailCodes.value?.some((code) => type.test(code));
    }

    return !!errorDetailCodes.value?.includes(type);
  }

  function anyErrorCodeContains(type: string | RegExp) {
    const checkErrorType = isErrorType(type);

    if (checkErrorType) {
      return true;
    }

    return someErrorDetailsContains(type);
  }

  return {
    isApiError: computed(() => isApiError(errorRef.value)),
    isErrorType,
    someErrorDetailsContains,
    anyErrorCodeContains,
    errorDetails,
    errorDetailCodes,
    errorType,
  };
}
