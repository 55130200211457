import { useLocalStorage } from '@vueuse/core';
import axios from 'axios';
import { defineStore } from 'pinia';

import { getLoginRedirectUrl, getLogoutRedirectUrl, getToken, refreshToken } from '@/api/services/auth-service';
import { useAuthLoggedInCustomerStore } from '@/stores/auth-logged-in-customer-store';
import type { IOAuth2TokenResponse } from '@/types/api';
import { getAuthStateCode } from '@/utils/auth-utils';
import { getAuthSettings } from '@/utils/env-utils';

const authStateCode = useLocalStorage('auth-state-code', '');

/**
 * Add any auth-related store here. These are used to sync them to the login state
 */
const authStoreChildren = [useAuthLoggedInCustomerStore];

export interface IAuthStateModel {
  createdAt: null | number;
  response: null | IOAuth2TokenResponse;
  isExpired: null | boolean;
  authState: null | 'not-logged-in' | 'logged-in' | 'logging-in' | 'refreshing-token' | 'undetermined';
}

export const useAuthStore = defineStore('auth', {
  persist: true,
  state: (): IAuthStateModel => ({
    createdAt: null,
    response: null,
    isExpired: null,
    authState: 'undetermined',
  }),
  getters: {
    accessToken: (state) => state.response?.access_token,
    refreshToken: (state) => state.response?.refresh_token,
    isLoggedIn: (state) => state.response?.access_token && !state.isExpired && state.authState === 'logged-in',
    isLoggingIn: (state) => state.authState === 'logging-in',
    isRefreshingToken: (state) => state.authState === 'refreshing-token',
    isLoggedOut: (state) => state.authState === 'not-logged-in',
    logoutUrl: (state) => getLogoutRedirectUrl(state.response?.access_token),
    authHeader: (state) => {
      const headers: Record<string, string> = {};

      if (state.response?.access_token) {
        headers['Authorization'] = `Bearer ${state.response?.access_token}`;
      }

      return headers;
    },
  },
  actions: {
    /**
     * Initializes the session. Must be called before the app launches.
     * Ideally called before the router is set up.
     */
    async initializeSession() {
      authStoreChildren.forEach((init) => init());

      if (!this.accessToken) {
        // User has no local session. Reset everything to "not logged in".
        this.createdAt = 0;
        this.response = null;
        this.isExpired = null;
        this.authState = 'not-logged-in';
        return Promise.all(
          authStoreChildren.map((childStore) => {
            return childStore().onAfterLogout?.();
          }),
        );
      }

      // If token is available, perform a request against a fast secured API endpoint.
      // If it responds with 200 the access token is valid and we can proceed to the app.
      const headers = this.authHeader;

      const { checkUrl } = getAuthSettings();

      return axios
        .get(checkUrl, {
          headers,
        })
        .then(() => {
          // All fine. Proceed to app.
          this.authState = 'logged-in';
          return this.onAfterLogin();
        })
        .catch(() => {
          const currentRefreshToken = this.response?.refresh_token;

          if (!currentRefreshToken) {
            return this.logout();
          }

          // Try a token refresh
          this.authState = 'refreshing-token';
          return refreshToken(currentRefreshToken).then((response) => {
            this.setAuthResponse(response.data);
            return this.onAfterLogin();
          });
        })
        .catch(() => {
          // No refresh token or refresh failed. Logout and proceed to app.
          return this.logout();
        });
    },

    /**
     * Gets the auth token from the given OAuth code after the redirect
     * @param stateCode the local stored auth state code. It must match the one in the redirect query parameter
     * @param authCode the auth code from the redirect query parameter
     */
    fetchToken(stateCode: string, authCode: string) {
      // only if the locally stored code and the code from the redirect query parameter match, we can proceed.
      if (authStateCode.value && stateCode === authStateCode.value) {
        this.authState = 'logging-in';
        return getToken(authCode)
          .then((response) => {
            this.setAuthResponse(response.data);
            return this.onAfterLogin();
          })
          .catch(() => {
            this.authState = 'not-logged-in';
            return this.onAfterLogout();
          });
      } else {
        // state code does not match the one of the session. The login attempt is invalid.
        authStateCode.value = '';
        this.$reset();
        this.authState = 'not-logged-in';
        return this.onAfterLogout().then(() => {
          return Promise.reject(new Error('Invalid state code'));
        });
      }
    },

    /**
     * Redirects the user to the SSO login page.
     */
    login() {
      this.$reset();
      this.authState = 'logging-in';
      authStateCode.value = getAuthStateCode();
      window.location.href = getLoginRedirectUrl(authStateCode.value);
    },

    async onBeforeLogout() {
      return Promise.all(
        authStoreChildren.map((childStore) => {
          return childStore().onBeforeLogout?.(this);
        }),
      ).then(() => undefined);
    },

    async onAfterLogin(): Promise<void> {
      return Promise.all(
        authStoreChildren.map((childStore) => {
          return childStore().onAfterLogin?.(this);
        }),
      ).then(() => undefined);
    },

    async onAfterLogout() {
      return Promise.all(
        authStoreChildren.map((childStore) => {
          return childStore().onAfterLogout?.();
        }),
      ).then(() => undefined);
    },

    /**
     * logs out the user by sending a logout request and resetting the local session
     */
    async logout() {
      await Promise.all(
        authStoreChildren.map((childStore) => {
          return childStore().onBeforeLogout?.(this);
        }),
      );
      this.$reset();
      this.authState = 'not-logged-in';
      await this.onAfterLogout();
    },

    /**
     * Sets the auth response and sets the user as logged in.
     */
    setAuthResponse(authResponse: IOAuth2TokenResponse) {
      this.createdAt = Date.now();
      this.response = authResponse;

      if (authResponse) {
        this.authState = 'logged-in';
      }
    },
  },
});
