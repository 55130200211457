export function parseHost(value?: string): string | undefined {
  if (value) {
    try {
      const parsed = new URL(value);
      return parsed.host || value;
    } catch (e) {
      return value;
    }
  }

  return undefined;
}

export function getHost(): string | undefined {
  const host = parseHost(window.location.origin);

  if (host?.includes('127.0.0.1')) {
    return 'localhost';
  }

  return host;
}
