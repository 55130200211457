import type { UseQueryReturnType } from '@tanstack/vue-query';
import { computed, toRaw, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useTranslationQuery } from '@/api/queries/use-translations-query';
import type { ITranslationWithValidationMessages } from '@/api/services/translation-service';
import { defaultLanguage } from '@/config/i18n-config';
import type { IResponse } from '@/types/api';

/**
 * Downloads remote translations and caches them in the local storage.
 * If a request fails it will use the cached translations.
 */
export function useRemoteTranslations() {
  const { locale, setLocaleMessage } = useI18n();

  // Remove legacy local storage entries:
  Object.keys(localStorage)
    .filter((key) => key.startsWith('translations-'))
    .forEach((key) => localStorage.removeItem(key));

  // Default language query
  const defaultLanguageQuery = useTranslationQuery(defaultLanguage);

  // User selected query
  const userLanguageQuery = useTranslationQuery(locale);
  const isLoading = computed(() => defaultLanguageQuery.isLoading.value || userLanguageQuery.isLoading.value);

  watch(
    [locale, userLanguageQuery.data, defaultLanguageQuery.data],
    () => {
      if (defaultLanguageQuery.data.value) {
        setLoadedLanguageOrFallback(defaultLanguage, defaultLanguageQuery);
      }

      if (locale.value !== defaultLanguage && userLanguageQuery.data.value) {
        setLoadedLanguageOrFallback(locale.value, userLanguageQuery);
      }
    },
    { immediate: true },
  );

  function setLoadedLanguageOrFallback(
    locale: string,
    query: UseQueryReturnType<IResponse<ITranslationWithValidationMessages>, unknown>,
  ) {
    const { data, error } = query;
    const translations = data.value?.data;

    if (translations && !error.value) {
      setLocaleMessage(locale, toRaw(translations));
    }
  }

  return {
    isLoading,
  };
}
