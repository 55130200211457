import { useQuery } from '@tanstack/vue-query';
import type { MaybeRef } from 'vue';
import { computed, ref } from 'vue';

import { getLocations } from '@/api/services/location-service';

export function useLocationConfigQuery(locationIds: MaybeRef<number[]>) {
  const locationIdsRef = ref(locationIds);
  const ids = computed(() => locationIdsRef.value.sort());
  const enabled = computed(() => ids.value.length > 0);

  return useQuery(
    getLocationQueryKey(ids.value),
    () => {
      return getLocations(ids.value);
    },
    {
      enabled,
      refetchInterval: 60000,
      keepPreviousData: true,
    },
  );
}

export function getLocationQueryKey(locationIds: number[]) {
  return ['locations', locationIds];
}
