<template>
  <div class="gap-4 sm:block md:flex md:gap-8 xl:gap-16">
    <section class="flex-1">
      <slot />
    </section>
    <aside class="mt-8 hidden w-full flex-none md:mt-0 md:block md:w-72 lg:w-96 xl:w-[430px]">
      <slot name="aside" />
    </aside>
  </div>
</template>
