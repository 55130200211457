import { isEnv } from '@/utils/env-utils';

export function setupGA(token: string) {
  if (!token || isEnv(['develop'])) {
    return;
  }

  const url = `https://www.googletagmanager.com/gtag/js?id=${token}`;
  const matches = document.querySelector(`script[src="${url}"]`);

  if (matches != null) {
    // skip because it was already added
    return;
  }

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.setAttribute('async', 'true');
  script.setAttribute('src', url);
  document.getElementsByTagName('head').item(0)?.appendChild(script);

  const gaCode = document.createElement('script');
  gaCode.innerHTML = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${token}');
`;
  document.getElementsByTagName('head').item(0)?.appendChild(gaCode);
}

export function setupGTM(token: string) {
  if (!token || isEnv(['develop'])) {
    return;
  }

  const url = `https://www.googletagmanager.com/ns.html?id=${token}`;
  const matches = document.querySelector(`iframe[src="${url}"]`);

  if (matches != null) {
    // skip because it was already added
    return;
  }

  const initTag = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  initTag.appendChild(iframe);

  const body = document.getElementsByTagName('body').item(0);
  const head = document.getElementsByTagName('head').item(0);

  if (!body || !head) {
    console.warn('no body tag and head tag found');
    return;
  }

  body.insertBefore(initTag, body.firstChild);

  const gtmCode = document.createElement('script');
  gtmCode.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${token}');`;
  head.insertBefore(gtmCode, head.firstChild);
}
