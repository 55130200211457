import { useStorage } from '@vueuse/core';
import { debounce } from 'lodash-es';
import { deepClone } from 'vue-composable';

import type { IBookingFlowPredefinedSessionData } from '@/modules/booking-flow/types/booking-flow-types';

export interface IBookingFlowStorePersistenceReturn {
  save(state: IBookingFlowPredefinedSessionData): Promise<void>;

  saveDebounced(state: IBookingFlowPredefinedSessionData): Promise<void>;

  get(): Promise<IBookingFlowPredefinedSessionData>;
}

export function useBookingFlowStorePersistence(): IBookingFlowStorePersistenceReturn {
  const localStorage = useStorage('booking-flow', {} as IBookingFlowPredefinedSessionData);
  let stateToSaveDebounced: IBookingFlowPredefinedSessionData | undefined = undefined;

  const returnValue: IBookingFlowStorePersistenceReturn = {
    async save(dataToSave: IBookingFlowPredefinedSessionData) {
      if (!dataToSave) {
        return;
      }
      localStorage.value = dataToSave;
    },
    async saveDebounced(state: IBookingFlowPredefinedSessionData) {
      stateToSaveDebounced = deepClone(state);
      return saveDebounced();
    },
    async get() {
      return localStorage.value;
    },
  };

  const saveDebounced = debounce(() => {
    if (!stateToSaveDebounced) {
      return Promise.resolve();
    }

    return returnValue.save(stateToSaveDebounced);
  }, 500);

  return returnValue;
}
