<template>
  <div class="ui-skeleton-base relative cursor-wait overflow-hidden bg-gray-500 leading-4 after:opacity-70">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
$width: 200px;

.ui-skeleton-base {
  &:after {
    @apply absolute skew-x-12;
    left: $width * -0.5;
    right: $width * -0.5;
    top: 0;
    bottom: 0;

    content: '';
    background-image: linear-gradient(
      to right,
      transparent 0%,
      rgba(var(--color-gray-100), 0.3) 10%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(var(--color-gray-100), 0.3) 90%,
      transparent 100%
    );
    background-size: $width 100%;
    background-repeat: no-repeat;
    animation: shimmer 1000ms ease-in-out infinite;
  }
}

@keyframes shimmer {
  0% {
    background-position: ($width * -1) 0;
  }

  100% {
    background-position: top 0 right ($width * -1);
  }
}
</style>
