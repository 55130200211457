import { isFunction, set } from 'lodash-es';
import { useI18n } from 'vue-i18n';

import { useBookingFlowStore } from '@/modules/booking-flow/stores/booking-flow-state';
import type { ICustomerData, ICustomerFormData } from '@/modules/booking-flow/types/booking-flow-types';
import { fromStoreToCustomerForm } from '@/modules/booking-flow/utils/booking-flow-utils';
import { useAppStore } from '@/stores/app-store';
import type { IAuthCustomer, ICustomerPayload, ISetupIntentPayload, ISimpleIdStringNameEntity } from '@/types';
import type { Maybe } from '@/types/utility-types';
import { formatISODate } from '@/utils/date-utils';

export function getCustomerPayload(form: Partial<ICustomerFormData>): ICustomerPayload {
  const r = requiredOrThrow;

  return {
    customerType: r(form.customerType, 'customerType'),
    street: r(form.street, 'street'),
    city: r(form.city, 'city'),
    postalCode: r(form.zip, 'zip'),
    companyName: form.customerType === 'business' ? r(form.companyName, 'companyName') : form.companyName,
    companyTaxIdentificationNumber: form.vatNumber || null,
    firstname: r(form.firstName, 'firstName'),
    lastname: r(form.lastName, 'lastName'),
    countryId: r(form.countryId, 'country.id'),
    phoneNumber: r(form.phone?.number, 'phone'),
    phonePrefix: r(form.phone?.prefix, 'phone'),
    email: r(form.email, 'email'),
    acceptedTerms: r(form.conditions && form.privacy && form.items && form.cancellation, 'terms'),
  };
}

export function requiredOrThrow<T>(data: T | undefined | null, errorHint = ''): T {
  if (data == null) {
    throw new Error(errorHint ? 'value null/undefined: ' + errorHint : 'given data is null or undefined');
  }

  return data as T;
}

export function getCustomerFormDataAndValidate(givenCustomer: Partial<ICustomerFormData>) {
  const customer = getCustomerPayload(givenCustomer);
  const r = requiredOrThrow;

  return {
    countryId: r(customer.countryId, 'countryId'),
    lastname: r(customer.lastname, 'lastname'),
    firstname: r(customer.firstname, 'firstname'),
    companyTaxIdentificationNumber: customer.companyTaxIdentificationNumber || undefined,
    companyName: customer.customerType === 'business' ? r(customer.companyName, 'companyName') : undefined,
    postalCode: r(customer.postalCode, 'postalCode'),
    city: r(customer.city, 'city'),
    street: r(customer.street, 'street'),
    customerType: r(customer.customerType, 'customerType'),
    email: r(customer.email, 'email'),
    phoneNumber: r(customer.phoneNumber, 'phone'),
    phonePrefix: r(customer.phonePrefix, 'phone'),
    acceptedTerms: r(customer.acceptedTerms, 'terms'),
  };
}

export function getCustomerCreatePayload(options: {
  locale: string;
  locationId: number;
  recaptchaToken: string;
  moveInDate: string;
  bookingPlanId: number;
  unitTypeId: number;
  unitTypeCategoryId: number;
  insuranceId?: number;
  insuranceBillingPeriodId: number;
  discountCode?: string | undefined;
  customer: ICustomerFormData;
}): ISetupIntentPayload {
  const r = requiredOrThrow;

  const customerPayload = getCustomerFormDataAndValidate(options.customer);

  return {
    moveInDate: formatISODate(r(options.moveInDate, 'bookingStore.moveInDate?.toISOString()')),
    ...customerPayload,

    billingPeriodId: r(options.bookingPlanId, 'billingPeriodId'),
    insuranceBillingPeriodId: options.insuranceBillingPeriodId,
    discountCode: options.discountCode,
    unitTypeId: r(options.unitTypeId, 'unitTypeId'),
    unitTypeCategoryId: r(options.unitTypeCategoryId, 'unitTypeCategoryId'),
    insuranceId: options.insuranceId,

    languageId: options.locale.toUpperCase(),
    locationIds: [options.locationId as number],
    recaptchaToken: options.recaptchaToken,
  };
}

export function useCreateCustomerPayload() {
  const bookingStore = useBookingFlowStore();
  const locationStore = useAppStore();
  const { locale } = useI18n();

  return {
    getCustomerPayload(recaptchaToken: string) {
      return getCustomerCreatePayload({
        recaptchaToken,
        // here the customer data must have been validated multiple times so that we can assume that it is valid and filled out
        customer: fromStoreToCustomerForm(bookingStore.customerData as ICustomerFormData),
        bookingPlanId: bookingStore.bookingPlan?.id as number,
        discountCode: bookingStore.discountCode,
        insuranceId: bookingStore.insurance?.id as number,
        insuranceBillingPeriodId: bookingStore.insurance?.billingPeriod?.id as number,
        locationId: locationStore.location?.id as number,
        locale: locale.value,
        moveInDate: bookingStore.moveInDate as string,
        unitTypeId: bookingStore.unitType?.id as number,
        unitTypeCategoryId: bookingStore.unitTypeCategory?.id as number,
      });
    },
  };
}

export function getFullName(item?: { firstname?: Maybe<string>; lastname?: Maybe<string> }) {
  const fullName = [item?.firstname, item?.lastname]
    .map((name) => name?.trim())
    .filter((name) => name)
    .join(' ');

  return fullName ? fullName : '';
}

export function getCustomerFormDefaults(
  loggedInCustomer?: IAuthCustomer,
  countries: ISimpleIdStringNameEntity[] = [],
): Partial<ICustomerData> {
  const result: Partial<ICustomerData> = {};

  type FieldMap = Record<keyof ICustomerData, keyof IAuthCustomer | (() => unknown)>;

  const fieldMap: FieldMap = {
    firstName: 'firstname',
    lastName: 'lastname',
    zip: 'postalCode',
    companyName: 'companyName',
    country: () => {
      return countries.find((c) => c.id === loggedInCustomer?.countryId);
    },
    city: 'city',
    street: 'street',
    email: 'email',
    vatNumber: 'companyTaxIdentificationNumber',
    phone: () => {
      if (loggedInCustomer?.phonePrefix || loggedInCustomer?.phoneNumber) {
        return {
          number: loggedInCustomer.phoneNumber || '',
          prefix: loggedInCustomer.phonePrefix || '',
        };
      }

      return undefined;
    },
    customerType: () => {
      return loggedInCustomer?.type === 'business' ? 'business' : 'private';
    },
    items: () => false,
    privacy: () => false,
    conditions: () => false,
    recaptchaToken: () => undefined,
    cancellation: () => undefined,
  };

  if (loggedInCustomer) {
    Object.keys(fieldMap).forEach((key) => {
      const formProp = key as keyof ICustomerData;
      const mappedProp = fieldMap[formProp];
      let value;

      if (isFunction(mappedProp)) {
        value = mappedProp();
      } else {
        value = loggedInCustomer[mappedProp] || '';
      }

      set(result, formProp, value);
    });
  }

  return result;
}
