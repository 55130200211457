import { email } from '@vee-validate/rules';
import dayjs from 'dayjs';
import { isArray, isObject } from 'lodash-es';
import { useI18n } from 'vue-i18n';

import type { IPhoneNumber } from '@/types';
import { useLocalFormatter } from '@/utils/format-utils';

export function useValidation() {
  const { t } = useI18n();
  const { formatDate } = useLocalFormatter();

  function minArrayLength(value: unknown[], [limit]: [number]) {
    if (!value || !value.length) {
      return true;
    }

    if (value.length < limit) {
      return t('formValidation.minArrayLength', { length: limit });
    }
    return true;
  }

  function maxArrayLength(value: unknown[], [limit]: [number]) {
    if (!value || !value.length) {
      return true;
    }

    if (value.length > limit) {
      return t('formValidation.maxArrayLength', { length: limit });
    }
    return true;
  }

  function emailArray(value: string | string[]) {
    if (value && isArray(value)) {
      for (const item of value) {
        if (!email(item)) {
          return t('formValidation.email');
        }
      }
    }
    return true;
  }

  function phoneNumber(value: undefined | null | string | IPhoneNumber) {
    if (!isObject(value)) {
      return true;
    }

    const trimmed = (value?.number || '').trim();

    // Skip check if no phone number was entered
    if (!trimmed) {
      return t('formValidation.required');
    }

    // Phone number needs to be at least 4 chars long
    if (!trimmed || trimmed.length < 3 || trimmed.startsWith('0') || trimmed.startsWith('+')) {
      return t('formValidation.phone');
      // If number is valid check if a prefix is available
    } else if (!value?.prefix || !(value.prefix || '').startsWith('+')) {
      return t('formValidation.phonePrefix');
    }

    return true;
  }

  function minDate(value: string | Date, [referenceDate]: [Date | string]) {
    if (referenceDate && value) {
      const valueObj = dayjs(value);
      const referenceDateObj = dayjs(referenceDate);

      if (valueObj.isValid() && referenceDateObj.isValid()) {
        if (valueObj.unix() < referenceDateObj.unix()) {
          return t('formValidation.minDate', { date: formatDate(referenceDate) });
        }
      }
    }

    return true;
  }

  function maxDate(value: string | Date, [referenceDate]: [Date | string]) {
    if (referenceDate && value) {
      const valueObj = dayjs(value);
      const referenceDateObj = dayjs(referenceDate);

      if (valueObj.isValid() && referenceDateObj.isValid()) {
        if (valueObj.unix() > referenceDateObj.unix()) {
          return t('formValidation.maxDate', { date: formatDate(referenceDate) });
        }
      }
    }

    return true;
  }

  return {
    maxArrayLength,
    minArrayLength,
    emailArray,
    phoneNumber,
    minDate,
    maxDate,
  };
}
