import { uniqueId } from 'lodash-es';
import type { RouteLocationRaw } from 'vue-router';

import router from '@/router';
import { navigationNames } from '@/router/router-constants';
import { useAuthStore } from '@/stores/auth-store';

const logoutRedirectTarget: RouteLocationRaw = { name: navigationNames.login };

export function logoutAndRedirect(target: RouteLocationRaw = logoutRedirectTarget) {
  const authStore = useAuthStore();

  return authStore.logout().finally(() => {
    return router.push(target);
  });
}

export function getAuthStateCode() {
  const code = Math.round(Math.random() * 1000000) + '-' + uniqueId() + '-' + Date.now();
  return code.trim();
}
