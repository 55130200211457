import { watchEffect } from 'vue';

import { useSetupDataQuery } from '@/api/queries/use-setup-data-query';
import { useSentry } from '@/composables/use-sentry';
import { useAppStore } from '@/stores/app-store';
import { useTranslationUtils } from '@/utils/translation-utils';

export async function useSetupData() {
  const { addTranslations } = useTranslationUtils();
  const appStore = useAppStore();
  const { data, error } = useSetupDataQuery();

  watchEffect(() => {
    if (data.value) {
      appStore.setupData = data.value.data;

      if (data.value?.data?.translations) {
        addTranslations(data.value.data.translations);
      }
    } else if (error.value) {
      const sentry = useSentry();
      sentry.captureException(error.value);
    }
  });
}
