import type { ILocationConfigItem } from '@/types';
import type { IResponse } from '@/types/api';
import { httpClient } from '@/utils/fetch-utils';

export function getLocations(locationIds: number[]): Promise<IResponse<ILocationConfigItem[]>> {
  return httpClient
    .get('/location/config', {
      params: { locationIds: locationIds.join(',') },
    })
    .then((response) => response.data);
}
