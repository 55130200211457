export interface IResponse<T> {
  data: T;
}

export interface IPagination {
  page: number;
  from: number;
  to: number;
  lastPage: number;
  pageSize: number;
  total: number;
}

export type IPaginationResponse = Components.Schemas.PaginationObject;

export interface IPaginatedResponse<T> {
  data: T;
  pagination: IPaginationResponse;
}

export interface IPaginationPayload {
  page: number;
  pageSize: number;
}

export const getDefaultPaginationValues = (pageSize = 10): IPaginationPayload => ({
  page: 1,
  pageSize,
});

export interface IApiErrorDetail {
  code: string;
  field: string;
  message: string;
}

export interface IApiError {
  error: {
    code: string;
    details: IApiErrorDetail[];
    message: string;
  };
}

export interface IOAuth2TokenResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface IUnit {
  id: number;
  name: string;
  moveInDate: string;
}
