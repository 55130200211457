import { isString } from 'lodash-es';

export const navigationNames = {
  accountOverview: 'account-overview',
  auth: 'auth',
  authAttempt: 'auth-attempt',
  login: 'login',
  logout: 'logout',
  forbidden: 'forbidden',
  serverError: 'server-error',
  notFound: 'not-found',

  customerPortalUpdatePaymentMethod: 'customer-portal-update-payment-method',
  customerPortalUpdatePaymentMethodVerify: 'customer-portal-update-payment-method-verify',
  customerPortalSharedAccess: 'customer-portal-shared-access',

  redirectIdCheck: 'customer-portal-redirect-id-check',
};

export const guardNames = {
  authenticatedGuard: 'authenticated-guard',
  notAuthenticatedGuard: 'not-authenticated-guard',
  hasSharedAccess: 'has-shared-access',
  requiresLanguage: 'requires-language',
} as const;

export type IGuardName = (typeof guardNames)[keyof typeof guardNames];

export function getMiddleware(middleware: IGuardName | IGuardName[]): { middleware: IGuardName[] } {
  const middlewares = isString(middleware) ? [middleware] : middleware;

  return {
    middleware: middlewares,
  };
}

export interface IAppRouteMeta {
  middleware?: IGuardName[];
  /**
   * If guard is `is-authenticated-guard` or ``
   */
  redirectOnLogout?: boolean;
}
