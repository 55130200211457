import { QueryClient, VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';
import type { App } from 'vue';

const defaultOptions = {
  queries: {
    staleTime: 15000,
    retry: 0,
    refetchOnWindowFocus: false,
  },
};

export const queryClient = new QueryClient({
  defaultOptions,
});

const options: VueQueryPluginOptions = { queryClient };

export function setupVueQuery(app: App) {
  app.use(VueQueryPlugin, options);
}
