import type { RouteRecordRaw } from 'vue-router';

import { getMiddleware, guardNames, navigationNames } from '@/router/router-constants';

export const authRoutes: RouteRecordRaw[] = [
  {
    name: navigationNames.authAttempt,
    path: '/auth/login',
    meta: {
      ...getMiddleware(guardNames.notAuthenticatedGuard),
    },
    component: () => import('@/modules/base/pages/AuthAttemptPage.vue'),
  },
  {
    // Auth callback path. Called by the auth api after successful login
    name: navigationNames.auth,
    path: '/auth',
    meta: {
      ...getMiddleware(guardNames.notAuthenticatedGuard),
    },
    component: () => import('@/modules/base/pages/AuthPage.vue'),
  },
  {
    name: navigationNames.login,
    path: '/login',
    meta: {
      ...getMiddleware(guardNames.notAuthenticatedGuard),
    },
    component: () => import('@/modules/base/pages/LoginPage.vue'),
  },
  {
    name: navigationNames.logout,
    path: '/logout',
    component: () => import('@/modules/base/pages/LogoutPage.vue'),
  },
];
