import { useLocalStorage } from '@vueuse/core';
import { intersection } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, onMounted, watchEffect } from 'vue';

import { useTrackingSnippetQuery } from '@/api/queries/use-tracking-snippet';
import { setupGA, setupGTM } from '@/api/services/google-tag-manager-service';
import { cookieConsentValues, type ICookieConsentSelection } from '@/modules/cookie/types/cookie-types';
import type { IThirdPartySnippetType } from '@/types';

const trackerMap: Partial<Record<IThirdPartySnippetType, (id: string) => void>> = {
  GA: setupGA,
  GTM: setupGTM,
};

export const useConsentStore = defineStore('consent', () => {
  const name = 'consent';
  const cookieValues = useLocalStorage<ICookieConsentSelection | object>(name, {});

  function setCookies(options: ICookieConsentSelection) {
    cookieValues.value = options;
  }

  function deleteCookies() {
    cookieValues.value = undefined;
  }

  const storedData = computed<ICookieConsentSelection | undefined>(() => {
    if (cookieValues.value) {
      return cookieValues.value as ICookieConsentSelection;
    }

    return undefined;
  });
  const cookieKeys = computed(() => Object.keys(storedData.value || {}).sort());

  const hasSeenCookieConsent = computed(
    () =>
      storedData.value != null &&
      intersection(cookieKeys.value, cookieConsentValues).length === cookieConsentValues.length,
  );

  onMounted(() => {
    const { data: snippetResponse } = useTrackingSnippetQuery(
      computed(() => {
        return storedData.value?.marketing === true;
      }),
    );
    const trackingSnippets = computed(() => snippetResponse.value?.data || []);

    watchEffect(() => {
      if (trackingSnippets.value) {
        trackingSnippets.value.forEach((tracking) => {
          const setupFn = trackerMap[tracking.type];

          if (setupFn) {
            setupFn(tracking.typeId);
          }
        });
      }
    });
  });

  return {
    setCookies,
    deleteCookies,
    hasMarketingCookies: computed(() => storedData.value?.marketing === true),
    hasNecessaryCookies: computed(() => storedData.value?.necessary === true),
    hasSeenCookieConsent,
  };
});
