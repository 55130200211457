import type { LocaleMessage } from '@intlify/core-base';
import { isObject, isString, set } from 'lodash-es';

import type { IResponse } from '@/types/api';
import { httpClient } from '@/utils/fetch-utils';

export type ITranslationMessages = LocaleMessage;
export type ITranslationWithValidationMessages = ITranslationMessages & { formValidation: Record<string, string> };

const toEscape = '@'.split('');

function getSanitizedValue(stringValue: string): string {
  return stringValue
    .split('')
    .map((char) => {
      if (toEscape.includes(char)) {
        return `{'${char}'}`;
      }

      return char;
    })
    .join('');
}

function replaceInJson(obj: unknown) {
  if (isObject(obj)) {
    Object.keys(obj).forEach((_key) => {
      const key = _key as keyof typeof obj;
      if (isObject(obj[key])) {
        replaceInJson(obj[key]);
      } else if (isString(obj[key])) {
        set(obj, key, getSanitizedValue(obj[key]));
      }
    });
  }
}

export function getTranslations(language: string): Promise<IResponse<ITranslationWithValidationMessages>> {
  const headers = {
    'Content-Language': language,
  };

  return httpClient
    .get<IResponse<ITranslationWithValidationMessages>>('/translation', {
      headers,
    })
    .then((res) => {
      replaceInJson(res.data);
      return res.data;
    });
}
