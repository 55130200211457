import type { AxiosError, AxiosResponse } from 'axios';
import { isString, merge } from 'lodash-es';
import type { FormContext, GenericObject, Path } from 'vee-validate';
import type { MaybeRef } from 'vue';
import { computed, ref, unref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useApiErrorParser } from '@/composables/use-api-error-parser';
import type { IApiError, IApiErrorDetail } from '@/types/api';
import type { errorCodes } from '@/utils/error-code-utils';

export type IAppError<TCode extends keyof errorCodes> = AppError<TCode, errorCodes[TCode]>;

export class AppError<TCode extends keyof errorCodes, TData extends errorCodes[TCode]> extends Error {
  constructor(public readonly code: keyof errorCodes, public data: TData | undefined = undefined) {
    super(code);
  }
}

export function isAppError(error: unknown): error is AppError<keyof errorCodes, errorCodes[keyof errorCodes]> {
  return error instanceof AppError;
}

export function isSpecificAppError<TCode extends keyof errorCodes>(
  error: unknown,
  code: TCode,
): error is AppError<TCode, errorCodes[TCode]> {
  return error instanceof AppError && error.code === code;
}

export function createAppError<TCode extends keyof errorCodes, TData extends errorCodes[TCode]>(
  code: TCode,
  data?: TData,
) {
  return new AppError(code, data);
}

/**
 * Type guard that checks if the response from the backend is an API error.
 * That means it checks weather the response has the correct structure.
 */
export function isApiResponseError(e: unknown): e is { response: AxiosResponse<IApiError> } {
  const error = e as AxiosError;
  return isApiError(error?.response?.data);
}

/**
 * It validates if the given data is an API error.
 */
export function isApiError(e: unknown): e is IApiError {
  const error = e as IApiError;

  return error?.error?.code != null && error?.error?.message != null && error?.error?.details != null;
}

/**
 * Checks if the given response is a AxiosError
 */
export function isAxiosErrorResponse(e: unknown): e is AxiosError {
  return (e as AxiosError)?.response?.status != null;
}

export function getApiError(error: Error | IApiError | undefined | unknown): IApiError | undefined {
  if (isApiError(error)) {
    return error;
  }

  if (isApiResponseError(error)) {
    return error.response?.data;
  }

  return undefined;
}

type IMatchResult = Record<string, IApiErrorDetail[]>;

interface IGroupedErrorResult {
  /**
   * The mapped errors. These where passed as mappings
   */
  mapped: IMatchResult;
  /**
   * The non-matched errors. These where not passed as mappings
   */
  others: IMatchResult;

  /**
   * All combined
   */
  all: IMatchResult;
}

export type IMatcher = string | RegExp;
export type IFormFieldMapping<T = string> = Record<keyof T | string, IMatcher[]>;

/**
 * Matches a string against a matcher (string or regex).
 * @return true if matched
 * @param fieldName a string to match
 * @param matcher a string or regex to match against
 */
export function isMatch(fieldName: string, matcher: IMatcher) {
  if (isString(matcher)) {
    return fieldName === matcher;
  }

  return matcher.test(fieldName);
}

/**
 * Matches a single string against multiple matchers
 * @return true if one matcher matches
 * @param fieldName a string to match
 * @param matcher strings or regexes to match against
 */
export function isMatchMultiple(fieldName: string, matcher: IMatcher[]) {
  return matcher.some((matcher) => isMatch(fieldName, matcher));
}

/**
 * Takes a mapping-information (contains a name and matchers). If the given field matches the matchers of a mapping, the name of the matchers is returned.
 *
 * e.g.
 * fieldName: 'first_name',
 * mapping: {
 *   'firstName': ['firstname', 'first.name', 'first_name'],
 *   'foo': ['firstname', 'first.name', 'first_name'],
 * }
 * result: ['firstName', 'foo']
 *
 * @param fieldName the name to find matching names to
 * @param mapping a map containing a name and matchers
 */
export function getMatchResult<T>(fieldName: string, mapping: IFormFieldMapping<T>): (keyof IFormFieldMapping)[] {
  const results: (keyof IFormFieldMapping)[] = [];

  for (const mappedFieldName in mapping) {
    const matchers = mapping[mappedFieldName];

    if (isMatchMultiple(fieldName, matchers)) {
      results.push(mappedFieldName);
    }
  }

  return results;
}

/**
 * Returns the error details of a given IApiError
 * @param response
 */
export function getFieldErrors(response?: IApiError) {
  return response?.error?.details || [];
}

/**
 * Groups matched errors into mapped, others, all:
 * mapped: these errors matched some matchers in the mapper map
 * others: these errors were not contained in the mapper map
 * all: all errors combined in one map
 * @param errors
 * @param mappings
 */
export function getGroupedErrors<T>(
  errors: IApiErrorDetail[],
  mappings: IFormFieldMapping<T> = {} as IFormFieldMapping<T>,
): IGroupedErrorResult {
  const result: IGroupedErrorResult = {
    mapped: {},
    others: {},
    all: {},
  };

  for (const error of errors) {
    const name = error.field;
    const mappedNames = getMatchResult(name, mappings);

    if (mappedNames.length <= 0) {
      // No match. Push to others
      if (result.others[name] == null) {
        result.others[name] = [];
      }

      result.others[name].push(error);
    } else {
      mappedNames.forEach((newName) => {
        const key = newName as string;
        if (result.mapped[key] == null) {
          result.mapped[key] = [];
        }

        result.mapped[key].push(error);
      });
    }
  }

  const { mapped, others } = result;
  merge(result.all, mapped, others);

  return result;
}

/**
 * Returns a group of error names and the corresponding error-texts contained in a ApiError detail
 * @param groupedErrors
 * @param property
 */
export function getGroupedErrorMessages(groupedErrors: IGroupedErrorResult, property: keyof IApiErrorDetail = 'code') {
  const result: {
    mapped: Record<string, string[]>;
    others: Record<string, string[]>;
    all: Record<string, string[]>;
  } = {
    mapped: {},
    others: {},
    all: {},
  };

  for (const keyString in groupedErrors) {
    const key = keyString as keyof IGroupedErrorResult;
    const mappedErrors = groupedErrors[key];

    for (const field in mappedErrors) {
      result[key][field] = mappedErrors[field].map((e) => e[property]);
    }
  }

  return result;
}

export function useFormUtils<TInput extends GenericObject, TOutput = TInput>(
  form: MaybeRef<FormContext<TInput, TOutput> | undefined>,
) {
  const { t } = useI18n();
  const formRef = ref(form);

  /**
   * Assigns any API error to the form fields
   * @param response the errors sent from the server
   * Used to identify which errors depend on existing fields and which are global errors that do not match a given field
   * @param mapping Maps a name to a response error field name. Used to map for instance `firstname` to `firstName`
   */
  function assignErrors<T extends object>(response: unknown, mapping: IFormFieldMapping<T>) {
    if (formRef.value == null) {
      return;
    }

    const parsedError = useApiErrorParser(response);
    const formValues = formRef.value.values;
    const setFieldError = formRef.value.setFieldError;

    const formValueMapDefaults = {
      ...Object.keys(formValues).reduce((all, key) => {
        return {
          ...all,
          [key]: [key],
        };
      }, {} as Record<string, string[]>),
      ...mapping,
    } as IFormFieldMapping<T>;

    const grouped = getGroupedErrorMessages(
      getGroupedErrors(parsedError.errorDetails.value || [], formValueMapDefaults),
    );

    for (const field in grouped.all) {
      setFieldError(
        field as Path<TInput>,
        grouped.all[field].map((message) => t(message)),
      );
    }

    return grouped;
  }

  return {
    assignErrors,
    errors: computed(() => unref(formRef.value?.errors || ({} as Partial<Record<Path<TInput>, string | undefined>>))),
    values: computed(() => unref(formRef.value?.values || ({} as TInput))),
  };
}
