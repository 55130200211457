<template>
  <Popover
    v-if="items?.length"
    class="sm:relative"
  >
    <template #default="{ close, open }">
      <PopoverButton
        as="button"
        :active="open"
        :no-fill="true"
        class="flex gap-1 rounded border border-transparent px-2 py-0.5 !text-header-text outline-none focus-visible:outline focus-visible:outline-white/80"
        :class="{ 'border border-white': open }"
      >
        {{ $t('languages.' + currentItem?.id) }}
        <MaterialSymbolsKeyboardArrowDownRounded
          class="mt-0.5 transition-transform"
          :class="{ '-rotate-180': open }"
        />
      </PopoverButton>

      <PopoverPanel
        class="absolute inset-x-4 z-10 mt-2 min-w-[150px] rounded-2 bg-white py-2 shadow sm:left-auto sm:right-0"
      >
        <ul class="">
          <li
            v-for="lang in validItems"
            :key="lang.id"
          >
            <a
              class="block px-3 py-1 hover:bg-gray-100"
              :class="{
                'text-gray-700': modelValue !== lang.id,
                'font-semibold text-primary': modelValue === lang.id,
              }"
              href="#"
              @click.prevent="
                close();
                modelValue = lang.id;
              "
            >
              {{ $t(lang.name) }}
            </a>
          </li>
        </ul>
      </PopoverPanel>
    </template>
  </Popover>
</template>

<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { computed } from 'vue';

import type { ISimpleIdStringNameEntity } from '@/types';

interface Props {
  items?: ISimpleIdStringNameEntity[];
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
});

const modelValue = defineModel<string>();
const validItems = computed(() =>
  props.items?.map((i) => ({
    ...i,
    id: i.id.toLowerCase(),
  })),
);

const currentItem = computed(() => validItems.value?.find((l) => l.id === modelValue.value));
</script>

<style scoped lang="scss"></style>
