import dayjs from 'dayjs';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useAppStore } from '@/stores/app-store';

export function useCurrentLocation() {
  const route = useRoute();
  const locationStore = useAppStore();
  const location = computed(() => locationStore.location);
  const timeZone = computed(() => locationStore.location?.timeZone || '');
  const locationId = computed(() => location.value?.id);
  const routeLocationId = computed(() => +route.params.locationId);
  const externalLinks = computed(() => {
    return [
      {
        name: 'conditions',
        href: location.value?.termsAndConditionsUrl,
      },
      {
        name: 'privacy',
        href: location.value?.privacyPolicyUrl,
      },
      {
        name: 'items',
        href: location.value?.forbiddenItemsUrl,
      },
      {
        name: 'cancellation',
        href: location.value?.cancellationPolicyUrl,
      },
    ].filter((term) => term.href);
  });

  return {
    /**
     * The loaded location.
     */
    location,

    earliestAllowedBookingDate: computed(() => {
      const earliest = dayjs(location.value?.earliestBookableDate);
      const currentDayLocation = dayjs(location.value?.currentDayLocationTimeZone);
      const dateToUse =
        earliest.unix() > currentDayLocation.unix()
          ? location.value?.earliestBookableDate
          : location.value?.currentDayLocationTimeZone;

      const isValid = dayjs(dateToUse).isValid();
      return isValid ? dateToUse : undefined;
    }),
    /**
     * The location id of the loaded location. Will only be set once the location has been loaded.
     */
    locationId,
    /**
     * The location id from the route. Can be used to immediately update dependent requests without waiting for the location to be loaded.
     */
    routeLocationId,
    locationParams: computed(() => ({ locationId: locationId.value })),
    externalLinks,
    timeZone,
  };
}
