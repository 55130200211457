/* eslint-disable quotes */
import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

import { useAppInformation } from '@/composables/use-app-verison';

function log(...args: unknown[]) {
  // eslint-disable-next-line no-console
  console.log('[SENTRY]', ...args);
}

export function setupSentry(app: App, router: Router) {
  const { VITE_APP_SENTRY_ENABLED, VITE_APP_SENTRY_DSN } = import.meta.env;
  if (VITE_APP_SENTRY_ENABLED === 'true') {
    if (!VITE_APP_SENTRY_DSN) {
      log('⚠️ VITE_APP_SENTRY_ENABLED is set to "true" but no VITE_APP_SENTRY_DSN given!');
    } else {
      log('Started');
    }

    const appInformation = useAppInformation();

    const releaseName = `${appInformation.version}`;
    Sentry.init({
      app,
      dsn: VITE_APP_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost:3001', 'kinnovis.com', /^\//],
      release: releaseName,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [
        '$crisp is not defined',
        'jQuery is not defined',
        "Can't find variable: $crisp",
        "Can't find variable: jQuery",
        'Request failed with status code 422',
      ],
    });
  } else {
    log('Disabled');
  }
}
