export const defaultLocaleKey = 'locale';

export class I18nLocaleStorage {
  public readonly localeKey = defaultLocaleKey;

  constructor(public readonly fallbackLanguage: string, public readonly availableLanguages?: string[]) {}

  get() {
    const persisted = localStorage.getItem(this.localeKey);

    if (persisted && (!this.availableLanguages || this.availableLanguages.includes(persisted))) {
      return persisted;
    } else if (persisted && this.availableLanguages && !this.availableLanguages.includes(persisted)) {
      this.clear();
    }

    return this.fallbackLanguage;
  }

  set(locale: string) {
    if (locale === this.fallbackLanguage) {
      this.clear();
    } else if (!this.availableLanguages || this.availableLanguages.includes(locale)) {
      localStorage.setItem(this.localeKey, locale);
    }
  }

  clear() {
    localStorage.removeItem(this.localeKey);
  }
}

export function getBrowserLanguage(fallback: string) {
  return (navigator.language || fallback).split('-')[0]?.toLowerCase();
}
