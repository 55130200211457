import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router';

import type { IBookingOptionSection } from '@/modules/booking-flow/types/booking-flow-types';
import { guardNames, navigationNames } from '@/router/router-constants';

export const bookingFlowRouteNames = {
  continueFlow: 'booking-flow-unit-type-continue-flow',
  unitSelection: 'booking-flow-unit-type-selection',
  bookingOptions: 'booking-flow-options',
  unitTypeNotAvailable: 'booking-flow-unit-type-not-available',
  bookingBasket: 'booking-flow-basket',
  customerForm: 'booking-flow-customer-form',
  alreadyBooked: 'booking-flow-already-booked',
};

export const bookingFlowPageOder = [
  bookingFlowRouteNames.unitSelection,
  bookingFlowRouteNames.bookingOptions,
  bookingFlowRouteNames.bookingBasket,
  bookingFlowRouteNames.customerForm,
];

const commonRouteMeta = {
  middleware: [guardNames.requiresLanguage],
};

export const bookingFlowRoutes: RouteRecordRaw[] = [
  {
    path: '/:locationId(\\d+)',
    component: () => import('./pages/BookingFlowPage.vue'),
    meta: {
      ...commonRouteMeta,
    },
    children: [
      {
        name: bookingFlowRouteNames.continueFlow,
        path: 'continue',
        component: () => import('./pages/BookingFlowContinuePage.vue'),
      },
      {
        name: bookingFlowRouteNames.unitSelection,
        path: '',
        component: () => import('./pages/BookingFlowUnitSelectionPage.vue'),
      },
      {
        name: bookingFlowRouteNames.alreadyBooked,
        path: 'already-booked',
        component: () => import('./pages/BookingFlowAlreadyBookedErrorPage.vue'),
      },
      {
        path: 'unit-type/:unitTypeId(\\d+)',
        component: () => import('./pages/BookingFlowUnitTypePreSelectPage.vue'),
      },
      {
        name: bookingFlowRouteNames.bookingOptions,
        path: 'unit-type/:unitTypeId(\\d+)/options',
        component: () => import('./pages/BookingFlowOptionsPage.vue'),
        children: [
          {
            name: bookingFlowRouteNames.bookingBasket,
            path: 'basket',
            component: () => import('./pages/BookingFlowBasketPage.vue'),
          },
        ],
      },
      {
        name: bookingFlowRouteNames.unitTypeNotAvailable,
        path: 'unit-type/:unitTypeId(\\d+)/options/not-available/:moveInDate',
        component: () => import('./pages/BookingFlowUnitTypeNotAvailablePage.vue'),
      },
      {
        name: bookingFlowRouteNames.customerForm,
        path: 'unit-type/:unitTypeId(\\d+)/customer',
        component: () => import('./pages/BookingFlowCustomerFormPage.vue'),
      },
    ],
  },
];

export const routesFactoryBookingFlow = {
  goToBookingFlow(locationId?: number, unitTypeId?: number) {
    if (!locationId) {
      return {
        name: navigationNames.notFound,
      };
    }

    if (!unitTypeId) {
      return routesFactoryBookingFlow.unitTypeSelection();
    }

    return routesFactoryBookingFlow.customerForm(unitTypeId);
  },
  unitTypeSelection(unitTypeCategoryId?: number) {
    const route: RouteLocationRaw = {
      name: bookingFlowRouteNames.unitSelection,
    };

    if (unitTypeCategoryId) {
      route.query = {
        unitTypeCategoryId: unitTypeCategoryId,
      };
    }

    return route;
  },
  continueFlow() {
    return {
      name: bookingFlowRouteNames.continueFlow,
    };
  },
  customerForm(unitTypeId: number | undefined) {
    return {
      name: bookingFlowRouteNames.customerForm,
      params: {
        unitTypeId,
      },
    };
  },
  bookingOptions(unitTypeId: number | undefined, section?: IBookingOptionSection) {
    return {
      name: bookingFlowRouteNames.bookingOptions,
      query: {
        section,
      },
      params: {
        unitTypeId,
      },
    };
  },
  bookingBasket(unitTypeId: number | undefined) {
    return {
      name: bookingFlowRouteNames.bookingBasket,
      query: {},
      params: {
        unitTypeId,
      },
    };
  },
  unitTypeNotAvailable(unitTypeId: number | undefined, moveInDate: string) {
    return {
      name: bookingFlowRouteNames.unitTypeNotAvailable,
      params: {
        moveInDate,
        unitTypeId,
      },
    };
  },
  alreadyBooked(locationId: number | undefined) {
    return {
      name: bookingFlowRouteNames.alreadyBooked,
      params: {
        locationId,
      },
    };
  },
};
