import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { ILocationConfigItem, ISetupResponse } from '@/types';

export const useAppStore = defineStore(
  'app',
  () => {
    const location = ref<ILocationConfigItem | undefined>();
    const setupData = ref<ISetupResponse>();

    function setLocation(newLocation: ILocationConfigItem | undefined) {
      location.value = newLocation;
    }

    return {
      location,
      setupData,
      cookiePolicyUrl: computed(() => location.value?.privacyPolicyUrl),
      appearance: computed(() => setupData.value?.appearance),
      tenant: computed(() => setupData.value?.tenant),
      imprintUrl: computed(() => setupData.value?.imprintUrl),
      privacyPolicyUrl: computed(() => setupData.value?.privacyPolicyUrl),
      setLocation,
    };
  },
  {
    persist: true,
  },
);
