import type { Router } from 'vue-router';

import { hasSharedAccessGuard } from '@/router/guards/has-shared-access';
import { isAuthenticatedGuard } from '@/router/guards/is-authenticated-guard';
import { isNotAuthenticatedGuard } from '@/router/guards/is-not-authenticated-guard';
import { requiresLanguageGuard } from '@/router/guards/requires-language-guard';
import { guardNames } from '@/router/router-constants';
import { useAuthStore } from '@/stores/auth-store';
import { applyGuard } from '@/utils/guard-utils';

export function setupGuards(router: Router) {
  /**
   * Checks the session state before the first navigation attempt. It delays the first navigation until done.
   * Because if this the app starts with a clear session state and the guards do the rest.
   * The session check needs to be the first guard!
   *
   * Must always return true to allow the navigation.
   */
  let checked = false;

  router.beforeEach(async () => {
    // Only perform this check once on application start. This is what the checked variable is for.
    if (!checked) {
      checked = true;

      const authState = useAuthStore();
      return await authState
        .initializeSession()
        .then(() => {
          return true;
        })
        .catch(() => true);
    }

    return true;
  });

  // Setup of all other guards: Each guards is associated with a name defined in `guardNames`
  router.beforeEach(applyGuard(guardNames.authenticatedGuard, isAuthenticatedGuard));
  router.beforeEach(applyGuard(guardNames.notAuthenticatedGuard, isNotAuthenticatedGuard));
  router.beforeEach(applyGuard(guardNames.hasSharedAccess, hasSharedAccessGuard));
  router.beforeEach(applyGuard(guardNames.requiresLanguage, requiresLanguageGuard));
}
