import { useStorage } from '@vueuse/core';
import { computed, isRef } from 'vue';
import type { NavigationGuardWithThis } from 'vue-router';

import { getLocationQueryKey } from '@/api/queries/use-location-config-query';
import { getLocations } from '@/api/services/location-service';
import { i18n, i18nLocaleStorage } from '@/config/i18n-config';
import { queryClient } from '@/plugins/vue-query-plugin';
import { navigationNames } from '@/router/router-constants';
import { useAppStore } from '@/stores/app-store';
import type { ILocationConfigItem } from '@/types';
import { parseToInteger, parseToString } from '@/utils/format-utils';
import { getLocationLanguage } from '@/utils/location-utils';

export const requiresLanguageGuard: NavigationGuardWithThis<unknown> = (toRoute, _, next) => {
  const locationId = parseToInteger(toRoute.params.locationId, undefined);

  if (!locationId) {
    return next();
  }

  const storedLanguage = useStorage<string>('booking-flow-language', '');
  // This is the language that we eventually want to set
  const languageFromQuery = parseToString(toRoute.query.lang, undefined)?.toLowerCase();
  const appStore = useAppStore();
  const location = computed(() => appStore.location);

  const processLocation = (location: ILocationConfigItem) => {
    const { defaultLanguage, availableLanguageIds } = getLocationLanguage(location);
    const fallbackLanguage = defaultLanguage.id || i18nLocaleStorage.get();
    let languageToSet: string;
    const defaultBrowserLanguage = navigator?.language;

    if (!languageFromQuery && storedLanguage && availableLanguageIds.includes(storedLanguage.value)) {
      languageToSet = storedLanguage.value;
    } else if (languageFromQuery && availableLanguageIds.includes(languageFromQuery)) {
      languageToSet = languageFromQuery;
    } else if (defaultBrowserLanguage && availableLanguageIds.includes(defaultBrowserLanguage)) {
      languageToSet = defaultBrowserLanguage;
    } else {
      languageToSet = fallbackLanguage;
    }

    if (isRef(i18n.global.locale)) {
      i18n.global.locale.value = languageToSet;
    }
    i18nLocaleStorage.set(languageToSet);
    storedLanguage.value = languageToSet;

    if (toRoute.query.lang !== languageToSet) {
      return next({
        ...toRoute,
        query: {
          ...toRoute.query,
          lang: languageToSet,
        },
      });
    }

    return next();
  };

  if (location.value && location.value.id === locationId) {
    processLocation(location.value);
  } else {
    getLocations([locationId])
      .then((locationResponse) => {
        queryClient.setQueryData(getLocationQueryKey([locationId]), locationResponse);
        const locationData = locationResponse?.data?.[0];
        appStore.setLocation(locationData);
        processLocation(locationData);
      })
      .catch(() => {
        next({
          name: navigationNames.notFound,
        });
      });
  }
};
