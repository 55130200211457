import { captureException as $sentryCaptureException } from '@sentry/vue';

export function useSentry() {
  return {
    captureException:
      $sentryCaptureException != null
        ? $sentryCaptureException
        : // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
          (...params: Parameters<typeof $sentryCaptureException>) => {
            // Ignore
          },
  };
}
