import type { ILocationConfigItem } from '@/types';

const fallbackLanguage = {
  id: 'en',
  name: 'languages.en',
};

export function getLocationLanguage(location: ILocationConfigItem) {
  let availableLanguages = location.languages?.map((l) => ({
    ...l,
    id: l.id.toLowerCase(),
  }));

  if (!availableLanguages || availableLanguages.length <= 0) {
    availableLanguages = [fallbackLanguage];
  }

  const defaultLanguage = availableLanguages[0];

  return {
    availableLanguages,
    defaultLanguage,
    availableLanguageIds: availableLanguages.map((l) => l.id),
  };
}
