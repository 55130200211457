<template>
  <TransitionRoot
    appear
    :show="!!modelValue"
    as="template"
  >
    <Dialog
      class="relative z-modals"
      as="div"
      @close="handleClose"
    >
      <TransitionChild
        as="template"
        enter="duration-150 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-150 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/30" />
      </TransitionChild>

      <div
        class="fixed inset-0"
        :class="animationDone ? 'overflow-y-auto' : 'overflow-hidden'"
      >
        <div
          class="flex h-full flex-1 justify-center overflow-y-auto pt-3 text-center sm:items-center sm:pb-3"
          :class="{
            'items-end': position === 'bottom',
            'items-start': position === 'top',
          }"
        >
          <TransitionChild
            as="template"
            v-bind="animations"
            @before-enter="setAnimationDone(false)"
            @before-leave="setAnimationDone(false)"
            @after-enter="setAnimationDone(true)"
            @after-leave="emit('afterLeave')"
          >
            <DialogPanel
              class="relative max-h-full w-full overflow-hidden overflow-y-auto bg-white text-left align-middle shadow-md transition-all sm:max-w-2xl sm:rounded"
              :class="{
                'rounded-b-0 rounded-t': position === 'bottom',
                'rounded-b rounded-t-0': position === 'top',
              }"
            >
              <header
                class="sticky top-0 z-10 mx-2.5 flex gap-4 bg-white"
                :class="{
                  'pt-2': closeable,
                  'pt-4': density === 'md',
                  'pt-3': density === 'none',
                }"
              >
                <UIIconButton
                  v-if="closeable"
                  class="invisible"
                  variant="dark"
                  :title="$t('general.close')"
                >
                  <MaterialSymbolsCloseRounded />
                </UIIconButton>
                <div class="flex-1">
                  <DialogTitle
                    v-if="slots.header"
                    as="h2"
                    class="text-gray-900 mb-2 text-lg font-medium leading-6"
                  >
                    <slot
                      name="header"
                      v-bind="scopedProps"
                    />
                  </DialogTitle>
                </div>
                <UIIconButton
                  v-if="closeable"
                  variant="dark"
                  :title="$t('general.close')"
                  data-test-id="close-button"
                  @click="
                    emit('click:close');
                    scopedProps.close();
                  "
                >
                  <MaterialSymbolsCloseRounded />
                </UIIconButton>
              </header>
              <div :class="{ 'px-0': density === 'none', 'px-6 pb-6': density === 'md' }">
                <slot v-bind="scopedProps" />
              </div>

              <div
                v-if="slots.footer"
                class="sticky bottom-0"
              >
                <slot
                  name="footer"
                  v-bind="scopedProps"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { computed, ref, useSlots } from 'vue';

import UIIconButton from '@/modules/ui/components/UIIconButton.vue';

interface Props {
  modelValue?: boolean;
  persistent?: boolean;
  closeable?: boolean;
  position?: 'top' | 'bottom';
  density?: 'none' | 'md';
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  persistent: false,
  closeable: false,
  position: 'bottom',
  density: 'md',
});

const slots = useSlots();
const animationDone = ref(false);

const emit = defineEmits<{
  (e: 'update:modelValue', data: boolean): void;
  (e: 'afterLeave'): void;
  (e: 'click:close'): void;
}>();

function handleClose() {
  if (!props.persistent) {
    emit('update:modelValue', false);
  }
}

const scopedProps = computed(() => ({
  close: () => emit('update:modelValue', false),
}));

const animations = computed(() => {
  const options = {
    enter: 'duration-150 ease-out',
    'enter-from': 'opacity-0 sm:scale-95',
    'enter-to': 'opacity-100 translate-y-0 sm:scale-100',
    leave: 'duration-150 ease-in',
    'leave-from': 'opacity-100 translate-y-0',
    'leave-to': 'opacity-0 sm:scale-95',
  };

  switch (props.position) {
    case 'top':
      options['enter-from'] += ' -translate-y-full';
      options['leave-to'] += ' -translate-y-full';
      break;
    default:
      options['enter-from'] += ' translate-y-full';
      options['leave-to'] += ' translate-y-full';
  }

  return options;
});

let timeout: ReturnType<typeof setTimeout> | undefined;

function setAnimationDone(isDone: boolean) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = undefined;
  }

  if (isDone) {
    timeout = setTimeout(() => (animationDone.value = isDone), 250);
  } else {
    animationDone.value = isDone;
  }
}
</script>
