<template>
  <UILanguageSwitcher
    v-if="isEnabled"
    v-model="locale"
    :items="allLocales"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { HiddenFeature, useHiddenFeature } from '@/composables/use-hidden-feature';
import { languages } from '@/config/i18n-config';
import UILanguageSwitcher from '@/modules/ui/components/UILanguageSwitcher.vue';
import type { ISimpleIdStringNameEntity } from '@/types';

const { locale } = useI18n();
const { isEnabled } = useHiddenFeature(HiddenFeature.languageSwitch);

const allLocales = computed<ISimpleIdStringNameEntity[]>(() => {
  return languages.map((locale) => ({
    id: locale,
    name: 'languages.' + locale.toLowerCase(),
  }));
});
</script>

<style scoped lang="scss"></style>
