import { useQuery } from '@tanstack/vue-query';
import type { MaybeRef } from 'vue';
import { ref } from 'vue';

import { getTrackingSnippet } from '@/api/services/tracking-snipptes-service';

export function useTrackingSnippetQuery(enable: MaybeRef<boolean>) {
  const enableRef = ref(enable);
  return useQuery(['snippet'], () => getTrackingSnippet(), {
    enabled: enableRef,
    staleTime: Number.MAX_SAFE_INTEGER,
    retry: 3,
    retryDelay: 1000,
  });
}
