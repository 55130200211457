import type { Appearance } from '@stripe/stripe-js';

import { getHost } from '@/composables/get-host';
import type { IAppearanceHexColors, IAppearanceRGBColors, ITenantAppearanceConfig } from '@/types/appearance-types';
import { appearanceColors } from '@/types/appearance-types';
import { getApiEndpoint } from '@/utils/env-utils';

export const defaultColors: IAppearanceHexColors = {
  primary: '#265E92',
  secondary: '#C4DDF1',
  danger: '#CA3E3E',
  success: '#69CA4A',
  text: '#132B40',
  white: '#fff',
  black: '#000',
  link: '#265E92',
  'gray-100': '#FAFAFA',
  'gray-200': '#EBEDEF',
  background: '#FAFAFA',
  'gray-500': '#D5D5D5',
  disabled: '#D5D5D5',
  'gray-700': '#8E8E8E',
  darkDisabled: '#8E8E8E',
  'header-background': '#fff',
  'header-text': '#000',
};

/**
 * maps the API color names to the FE API color names
 * @param colors
 */
export function mapColors(colors: ITenantAppearanceConfig['colors']): IAppearanceHexColors {
  return {
    primary: colors.primary || defaultColors.primary,
    secondary: colors.secondary || defaultColors.secondary,
    danger: colors.error || defaultColors.danger,
    success: defaultColors.success,
    text: colors.text || defaultColors.text,
    white: '#fff',
    black: '#000',
    link: colors.primary || defaultColors.link,
    'gray-100': defaultColors['gray-100'],
    background: colors.background || defaultColors['gray-100'],
    'gray-200': defaultColors['gray-200'],
    'gray-500': defaultColors['gray-500'],
    disabled: colors.disabled || defaultColors['gray-500'],
    'gray-700': defaultColors['gray-700'],
    darkDisabled: colors.darkDisabled || defaultColors['gray-700'],
    'header-background': colors.headerBackground || '#fff',
    'header-text': colors.headerText || '#000',
  };
}

export function convertMapToRGB(colorMap: IAppearanceHexColors | undefined): IAppearanceRGBColors {
  return appearanceColors.reduce((all, colorName) => {
    return {
      ...all,
      [colorName]: getRGB(colorMap?.[colorName] || defaultColors[colorName]),
    };
  }, {} as Partial<IAppearanceRGBColors>) as IAppearanceRGBColors;
}

export function getRGB(color: string) {
  try {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    color = color.replace(shorthandRegex, (_, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
  } catch {
    return [0, 0, 0];
  }
}

export const defaultFavIconSizes = [32, 128, 180, 192];

export const defaultFavicon = {
  rel: 'icon',
  type: 'image/png',
  href: '/favicon.png',
  sizes: '32x32',
  referrerpolicy: 'origin',
} as const;

export function getFavIcons(sizes: number[] = defaultFavIconSizes) {
  const endpoint = getApiEndpoint();
  const host = getHost();
  const favicons: { rel: 'icon'; type: 'image/png'; href: string; sizes: string; referrerpolicy: 'origin' }[] = [];

  if (!sizes || !sizes.length) {
    favicons.push(defaultFavicon);
    return favicons;
  }

  sizes.forEach((size) => {
    favicons.push({
      rel: 'icon',
      type: 'image/png',
      href: `${endpoint}/favicon/${size}?rel=${host}`,
      sizes: `${size}x${size}`,
      referrerpolicy: 'origin',
    });
  });

  return favicons;
}

export function getStripeAppearance() {
  let appearance: Appearance = {};
  if (document?.body) {
    const style = getComputedStyle(document.body);
    const gray = `rgb(${style.getPropertyValue('--color-gray-500')})`;
    appearance = {
      variables: {
        colorPrimary: `rgb(${style.getPropertyValue('--color-primary')})`,
        colorBackground: `rgb(${style.getPropertyValue('--color-white')})`,
        colorText: `rgb(${style.getPropertyValue('--color-black')})`,
        colorTextPlaceholder: gray,
        colorDanger: `rgb(${style.getPropertyValue('--color-danger')})`,
        fontFamily: `${style.getPropertyValue('--font-family')}, Ideal Sans, system-ui, sans-serif`,
        spacingUnit: '4px',
        borderRadius: '4px',
      },
      rules: {
        '.Tab': {
          border: `1px solid ${gray}`,
          boxShadow: 'none',
          marginBottom: '16px',
        },
        '.Input': {
          border: `1px solid ${gray}`,
          boxShadow: 'none',
        },
      },
    };
  }
  return appearance;
}

export function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (outer.style as any).msOverflowStyle = 'scrollbar';
  document.body.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

export function isScrollbarVisible() {
  const viewportHeight = window.innerHeight;
  const scrollHeight = document.body.scrollHeight;
  return scrollHeight > viewportHeight;
}
