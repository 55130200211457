import type { MaybeRef } from 'vue';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

import { useLocationConfigQuery } from '@/api/queries/use-location-config-query';
import { useAppStore } from '@/stores/app-store';
import { isAxiosErrorResponse } from '@/utils/error-utils';
import { parseToInteger } from '@/utils/format-utils';

export function useLocationPrefetch(locationId: MaybeRef<number | undefined>) {
  const locationStore = useAppStore();

  const locationIdRef = ref(locationId);
  const locationIds = computed(() => (locationIdRef.value ? [locationIdRef.value] : []));
  const { data, isLoading, isError, error } = useLocationConfigQuery(locationIds);
  const location = computed(() => data.value?.data?.[0]);

  watchEffect(() => {
    locationStore.setLocation(location.value);
  });

  function setLocationId(id: number | undefined) {
    locationIdRef.value = id;
  }

  return {
    location,
    isLoading: computed(() => isLoading.value),
    isError: computed(() => isError.value),
    setLocationId,
    locationNotFound: computed(() => {
      if (isAxiosErrorResponse(error.value) && !isLoading.value) {
        return error.value?.response?.status === 404;
      }

      return false;
    }),
  };
}

/**
 * used to prefetch the location when the locationId is present in the url
 */
export function useAutoLocationPrefetch() {
  const route = useRoute();
  const locationId = computed(() => parseToInteger(route.params.locationId, undefined));
  return {
    ...useLocationPrefetch(locationId),
    locationId,
  };
}
