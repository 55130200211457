import type { NavigationGuardWithThis } from 'vue-router';

import { useAccountOverviewStore } from '@/modules/customer-portal/stores/account-overview-store';
import { navigationNames } from '@/router/router-constants';

export const hasSharedAccessGuard: NavigationGuardWithThis<unknown> = (to, from, next) => {
  const accountOverviewStore = useAccountOverviewStore();

  if (accountOverviewStore.canManagerAccess) {
    next();
  } else {
    next({ name: navigationNames.accountOverview });
  }
};
