import { useLocalStorage } from '@vueuse/core';
import { computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

/**
 * Add hidden features here!
 */
export enum HiddenFeature {
  languageSwitch = 'language-switch',
}

const values = Object.values(HiddenFeature);

/**
 * The prefix of the key in the storage
 */
const keyPrefix = 'feature-';

export function registerHiddenFeatures() {
  // Adds every feature to the storage. The composable takes care of initializing it or not
  values.forEach((feature) => {
    const key = `${keyPrefix}${feature}`;
    useLocalStorage(key, false);
  });

  // Removes removed features that are still persisted from the storage
  cleanup(localStorage);
}

export function cleanup(store: typeof localStorage | typeof sessionStorage) {
  // Gets all stored keys in the store
  const allKeys = Object.keys(store)
    .filter((k) => k.startsWith(keyPrefix))
    .map((k) => k.replace(keyPrefix, ''));

  // All keys from the store that are missing in the feature set must be removed
  const keysToRemove = allKeys.filter((v) => !values.includes(v as HiddenFeature));
  keysToRemove.forEach((k) => store.removeItem(`${keyPrefix}${k}`));
}

function toKey(feature: HiddenFeature) {
  return `${keyPrefix}${feature}`;
}

/**
 * This function can be used outside of setup to check wether a feature is enabled or not
 * @param feature
 */
export function isHiddenFeatureEnabled(feature: HiddenFeature) {
  return localStorage.getItem(toKey(feature)) === 'true';
}

export function useHiddenFeature(feature: HiddenFeature) {
  const key = toKey(feature);
  const storage = useLocalStorage(key, false);

  const route = useRoute();

  watchEffect(() => {
    const query = route.query;

    if (query[key] === 'true' || query[key] === '1') {
      storage.value = true;
    } else if (query[key] === 'false' || query[key] === '0') {
      storage.value = false;
    }
  });

  return {
    enable() {
      storage.value = true;
    },
    disable() {
      storage.value = false;
    },
    toggle() {
      storage.value = !storage.value;
    },
    isEnabled: computed(() => storage.value),
  };
}
