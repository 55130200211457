<template>
  <component
    :is="component"
    :to="to"
    type="button"
    :aria-pressed="active"
    :title="title"
    :disabled="disabled || loading"
    class="inline-flex h-9 w-9 items-center justify-center transition enabled:hover:opacity-70 disabled:cursor-not-allowed"
    :class="classes"
    @click="emit('click', $event)"
  >
    <UIIcon
      v-if="!loading"
      :no-fill="noFill"
      :responsive="responsive"
      :size="iconSize"
    >
      <slot />
    </UIIcon>
    <UIIcon
      v-else
      :no-fill="noFill"
      class="animate-spin"
      :responsive="responsive"
      :size="iconSize"
    >
      <MaterialSymbolsProgressActivity />
    </UIIcon>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { RouterLink } from 'vue-router';

import UIExternalLink from '@/modules/ui/components/UIExternalLink.vue';
import UIIcon from '@/modules/ui/components/UIIcon.vue';
import type { IIconSize } from '@/modules/ui/types/ui-icon-types';

interface Props {
  to?: RouteLocationRaw;
  disabled?: boolean;
  loading?: boolean;
  active?: boolean;
  raised?: boolean;
  noFill?: boolean;
  title?: string;
  variant?: 'primary' | 'dark' | 'header' | 'danger';
  iconSize?: IIconSize;
  responsive?: boolean;
  external?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  to: undefined,
  disabled: false,
  loading: false,
  active: false,
  raised: false,
  noFill: false,
  external: false,
  responsive: false,
  title: undefined,
  variant: 'primary',
  iconSize: 'md',
});

const emit = defineEmits<{ (e: 'click', data: Event): void }>();
const classes = computed(() => {
  const allClasses = ['active:text-primary'];

  if (props.disabled) {
    allClasses.push('text-gray-500 active:text-gray-500');
  } else if (props.active) {
    allClasses.push('text-secondary');
  } else if (props.variant === 'dark') {
    allClasses.push('text-text');
  } else if (props.variant === 'header') {
    allClasses.push('text-header-text');
  } else if (props.variant === 'danger') {
    allClasses.push('text-danger');
  } else {
    allClasses.push('text-primary');
  }

  if (props.raised) {
    allClasses.push('shadow rounded-full bg-white');
  }

  switch (props.iconSize) {
    case 'sm':
      allClasses.push('text-sm');
      break;
    case 'lg':
      allClasses.push('text-2xl');
      break;
    case 'xl':
      allClasses.push('text-5xl');
      break;
    default:
      allClasses.push('text-xl');
  }

  return allClasses;
});

const component = computed(() => {
  if (props.to && !props.disabled) {
    if (props.external) {
      return UIExternalLink;
    }

    return RouterLink;
  } else {
    return 'button';
  }
});
</script>
