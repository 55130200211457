import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { IPortalLocation } from '@/types';

/**
 * Used to share the selected location and the loading states on the account overview page
 */
export const useAccountOverviewStore = defineStore('accountOverviewLocationStore', () => {
  const location = ref<IPortalLocation | undefined>();
  return {
    location,
    locationId: computed(() => location.value?.id),
    canManagerAccess: computed(() => location.value?.showManageAccess),
  };
});
