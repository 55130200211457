import { localize } from '@vee-validate/i18n';
import { email, max, required } from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';
import { watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import { useValidation } from '@/composables/use-validation';

/**
 * Sets the translations for the current locale.
 */
export function setTranslations(lang: string, messages: Record<string, string>) {
  configure({
    // Generates an English message locale generator
    generateMessage: localize(lang, {
      messages,
    }),
  });
}

/**
 * Defines the rules and sets the translations for the current locale by watching the locale and the messages
 */
export function useVeeValidate() {
  const validation = useValidation();

  // Define the rule globally
  defineRule('required', required);
  defineRule('email', email);
  defineRule('minArrayLength', validation.minArrayLength);
  defineRule('maxArrayLength', validation.maxArrayLength);
  defineRule('maxLength', max);
  defineRule<string[]>('emailArray', validation.emailArray);
  defineRule('phoneNumber', validation.phoneNumber);
  defineRule('minDate', validation.minDate);
  defineRule('maxDate', validation.maxDate);

  const { locale, messages } = useI18n();

  watchEffect(() => {
    const languagesToSet: undefined | Record<string, string> = messages.value[locale.value]?.formValidation as Record<
      string,
      string
    >;
    if (languagesToSet) {
      setTranslations(locale.value, languagesToSet);
    }
  });
}
