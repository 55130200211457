import { useHead } from '@vueuse/head';
import type { MaybeRef } from 'vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useAppStore } from '@/stores/app-store';

/**
 * Sets the page title automatically by using the loaded tenant name and the given title.
 * Title format will be: `$title | $tenantName`
 * @param title The title to prefix the page title with
 */
export function usePageTitle(title?: MaybeRef<string | null | undefined>) {
  const { t } = useI18n();

  const appStore = useAppStore();
  const titleRef = ref(title);
  const tenantName = computed(() => appStore.tenant?.name || t('globalPageTitle.fallback'));
  const pageTitle = computed(() => {
    if (titleRef.value) {
      return [titleRef.value, tenantName.value].join(' | ');
    }

    return tenantName.value;
  });

  useHead({
    title: pageTitle,
  });

  return {
    pageTitle,
    tenantName,
  };
}
