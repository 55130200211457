import { useQuery } from '@tanstack/vue-query';
import type { MaybeRef } from 'vue';
import { ref } from 'vue';

import { getTranslations } from '@/api/services/translation-service';

export function useTranslationQuery(language: MaybeRef<string | undefined | null>) {
  const langRef = ref(language);

  return useQuery(['language', langRef], () => getTranslations(langRef.value as string), {
    enabled: !!langRef.value,
    staleTime: Number.MAX_VALUE,
  });
}
