<template>
  <div class="flex h-screen flex-col">
    <div class="flex-grow">
      <slot />
    </div>
    <UIFooter />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watchEffect } from 'vue';

import { useTenantAppearance } from '@/composables/use-tenant-appearance';
import UIFooter from '@/modules/ui/components/UIFooter.vue';

const { loadFonts, inlineStyles } = useTenantAppearance();

onMounted(() => {
  loadFonts();

  watchEffect(() => {
    document.body.setAttribute('style', inlineStyles.value);
  });
});
</script>

<style lang="scss"></style>
