import type { LocaleMessage } from '@intlify/core-base';
import { useI18n, type VueMessageType } from 'vue-i18n';

import { i18n } from '@/config/i18n-config';
import type { IBookingPlan, IDiscountCode, IPeriod } from '@/types';
import { useLocalFormatter } from '@/utils/format-utils';

export const t = (key: string, values?: unknown) => {
  return i18n.global.t(key, values as Record<string, unknown>);
};

export function useTranslationUtils() {
  const { t, locale, setLocaleMessage, getLocaleMessage } = useI18n();
  const { formatPercentage, formatCurrency } = useLocalFormatter();

  return {
    addTranslations(translations: LocaleMessage<VueMessageType>) {
      const existingMessages = getLocaleMessage(locale.value);
      setLocaleMessage(locale.value, {
        ...existingMessages,
        ...translations,
      });
    },

    getTranslatedPeriod(period: IPeriod) {
      if (period === 'weekly') {
        return t('periods.perWeek');
      }
      if (period === 'monthly') {
        return t('periods.perMonth');
      }
      return '';
    },

    getTranslatedBillingPeriod(data: Pick<IBookingPlan, 'period' | 'amount'>) {
      if (data?.period === 'weekly') {
        return t('periods.weeks', data?.amount);
      }
      if (data?.period === 'monthly') {
        return t('periods.months', data?.amount);
      }

      return '';
    },

    getTranslatedPeriodDuration(data?: Pick<IBookingPlan, 'period' | 'amount'> | null | undefined) {
      if (!data) {
        return '';
      }

      if (data?.period === 'weekly') {
        return t('periods.everyWeeks', data?.amount);
      }
      if (data?.period === 'monthly') {
        return t('periods.everyMonths', data?.amount);
      }

      return '';
    },

    getTranslatedDiscountCode(
      discount?:
        | Pick<IDiscountCode, 'discountType' | 'discountValue' | 'duration' | 'durationMonth'>
        | null
        | undefined,
    ) {
      if (discount == null) {
        return null;
      }

      let value = '';

      const translations = {
        forever: t('discount.offForever'),
        multiple_month: t('discount.offMonths', discount.durationMonth || 0),
        once: t('discount.offOnce'),
      };

      if (discount.discountType === 'percentage') {
        value = formatPercentage(discount.discountValue);
      } else {
        value = formatCurrency(discount.discountValue);
      }

      const parts = [value, translations[discount.duration]];

      return parts.join(' ');
    },
  };
}
