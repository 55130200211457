import '@/polyfills/container-queries';
import '@/assets/scss/tailwind.scss';
import '@/assets/scss/style.scss';
import '@/assets/scss/forms.scss';

import { createApp } from 'vue';

import { setupDayjs } from '@/plugins/dayjs-plugin';
import { setupI18n } from '@/plugins/i18n-plugin';
import { setupNotifications } from '@/plugins/notifications-plugin';
import { setupPinia } from '@/plugins/pinia-plugin';
import { setupRouter } from '@/plugins/router-plugin';
import { setupSentry } from '@/plugins/sentry-plugin';
import { setupTouchEvents } from '@/plugins/touch-events-plugin';
import { setupUseHead } from '@/plugins/use-head-plugin';
import { setupVirtualScroller } from '@/plugins/virtual-scroller-plugin';
import { setupVueMotion } from '@/plugins/vue-motion-plugin';
import { setupVueQuery } from '@/plugins/vue-query-plugin';
import router from '@/router';
import { logHiringMessage } from '@/utils/devtools-utils';

import App from './App.vue';

if (window !== window.parent) {
  const message =
    'Embedding the Kinnovis booking or customer portal via iFrame is not supported. Reach out to our support team in case you have any questions.';
  document.getElementsByTagName('body')[0].innerHTML = `<h2 class="text-2xl text-center mt-4">${message}</h2>`;
  throw new Error(message);
}

logHiringMessage();
const app = createApp(App);

setupRouter(app);
setupI18n(app);
setupDayjs();
setupPinia(app);
setupVueQuery(app);
setupVueMotion(app);
setupUseHead(app);
setupTouchEvents(app);
setupNotifications(app);
setupVirtualScroller(app);
setupSentry(app, router);

app.mount('#app');
