import { useIntervalFn } from '@vueuse/core';
import type { ComputedRef, UnwrapRef } from 'vue';
import { computed, ref } from 'vue';

/**
 * used to update data in a certain interval
 * @param fn
 * @param interval
 */
export function useUpdatedData<T>(fn: () => UnwrapRef<T>, interval: number): ComputedRef<T> {
  const data = ref<T>(fn() as T);

  useIntervalFn(() => {
    data.value = fn();
  }, interval);

  return computed(() => data.value as T);
}
