import { toRaw } from 'vue';

import type { ICustomerData, ICustomerFormData } from '@/modules/booking-flow/types/booking-flow-types';
import type { ICountryListItem } from '@/types';

export function fromCustomerFormToStore(
  formData: ICustomerFormData,
  options: {
    countries: ICountryListItem[];
  },
): ICustomerData {
  const selectedCountry = options.countries.find((c) => c.id === formData.countryId);

  if (formData.countryId && selectedCountry == null) {
    console.warn('🚨', formData.countryId, 'did not find country in ', toRaw(options.countries));
  }

  return {
    customerType: formData.customerType,
    city: formData.city,
    cancellation: formData.cancellation,
    companyName: formData.companyName,
    conditions: formData.conditions,
    country: selectedCountry || null,
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    phone: formData.phone,
    items: formData.items,
    privacy: formData.privacy,
    street: formData.street,
    zip: formData.zip,
    recaptchaToken: formData.recaptchaToken,
    vatNumber: formData.vatNumber,
  };
}

export function fromStoreToCustomerForm(storeData: Partial<ICustomerData> | undefined): ICustomerFormData {
  return {
    customerType: storeData?.customerType || 'private',
    city: storeData?.city || '',
    cancellation: !!storeData?.cancellation,
    companyName: storeData?.companyName,
    conditions: !!storeData?.conditions,
    countryId: storeData?.country?.id || null,
    email: storeData?.email || '',
    firstName: storeData?.firstName || '',
    lastName: storeData?.lastName || '',
    phone: storeData?.phone || null,
    items: !!storeData?.items,
    privacy: !!storeData?.privacy,
    street: storeData?.street || '',
    zip: storeData?.zip || '',
    recaptchaToken: storeData?.recaptchaToken,
    vatNumber: storeData?.vatNumber,
  };
}
