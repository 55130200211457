<template>
  <figure
    class="inline-block leading-none"
    :class="classes"
  >
    <span class="ui-icon block">
      <slot />
    </span>
  </figure>
</template>

<script lang="ts" setup>
import { computed, inject, provide } from 'vue';

import type { IIconSize } from '@/modules/ui/types/ui-icon-types';

interface Props {
  noFill?: boolean;
  size?: IIconSize;
  background?: boolean;
  responsive?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  noFill: false,
  size: 'md',
  background: false,
  responsive: false,
});

provide('ui-icon', true);

const hasParentIcon = inject('ui-icon', false);

if (hasParentIcon) {
  console.warn('UIIcon has a parent UIIcon');
}

const sizeClasses: Record<IIconSize, [string, string?]> = {
  sm: ['text-[20px]'],
  md: ['text-[24px]'],
  lg: ['text-[30px]', 'text-[24px] sm:text-[30px]'],
  xl: ['text-[32px]', 'text-[30px] sm:text-[32px]'],
  '2xl': ['text-[60px]', 'text-[50px] sm:text-[60px]'],
  '3xl': ['text-[80px]', 'text-[60px] sm:text-[80px]'],
  '4xl': ['text-[100px]', 'text-[80px] sm:text-[100px]'],
  auto: ['text-[1.4em]'],
};

const classes = computed(() => {
  const classes: string[] = [];

  if (props.background) {
    classes.push(
      'relative p-2.5 before:absolute before:inset-0 before:bg-current before:opacity-15 before:rounded-full',
    );
  }

  if (!props.noFill) {
    classes.push('fill');
  }

  const sizeClass = sizeClasses[props.size];

  if (!props.responsive) {
    classes.push(sizeClass[0]);
  } else if (props.responsive) {
    classes.push(sizeClass[1] || sizeClass[0]);
  }

  return classes;
});
</script>

<style scoped lang="scss">
.ui-icon {
  width: 1em;
  height: 1em;
}

.fill {
  &:deep(svg) {
    @apply fill-current leading-none;
    width: 100%;
    height: auto;
  }
}
</style>
