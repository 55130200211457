import dayjs from 'dayjs';
import { isNumber } from 'lodash-es';
import { computed } from 'vue';

import { useCurrentLocation } from '@/composables/use-current-location';
import { useUpdatedData } from '@/composables/use-updated-data';
import { getMostFutureDate, setToMidday, toMiddayDate } from '@/utils/date-utils';

export function useMoveInDate() {
  const { location, earliestAllowedBookingDate } = useCurrentLocation();

  const minDate = useUpdatedData(() => {
    if (earliestAllowedBookingDate.value) {
      return getMostFutureDate(
        toMiddayDate(earliestAllowedBookingDate.value) || setToMidday(new Date()),
        setToMidday(new Date()),
      );
    }

    return setToMidday(new Date());
  }, 60000);

  const maxDate = computed(() => {
    if (
      location.value?.bookableTill != null &&
      isNumber(location.value?.bookableTill) &&
      location.value?.bookableTill >= 0
    ) {
      return dayjs(minDate.value).add(location.value?.bookableTill, 'days').toDate();
    } else {
      return dayjs(minDate.value).add(2, 'months').toDate();
    }
  });

  return {
    minDate,
    maxDate,
  };
}
