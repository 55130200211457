import { ref, watch } from 'vue';

import { useAutoLocationPrefetch } from '@/api/queries/use-location-prefetch';
import { useLocalFormatter } from '@/utils/format-utils';

type IJaneGPTEvents = 'ready' | 'closed' | 'opened' | 'error';

interface ChatInstance {
  isOpen: boolean;
  destroy: () => void;
  open: () => void;
  close: () => void;
  setLanguage: (language: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on: (event: IJaneGPTEvents, callback: (...args: any[]) => void) => void;
}

declare global {
  interface Window {
    chatInstance: ChatInstance | undefined;
    janeGPT: (config: { language: string; apiKey: string }) => ChatInstance;
  }
}

export function useJaneGPTInitializer() {
  const { location, isLoading } = useAutoLocationPrefetch();
  const { localeWithCountry } = useLocalFormatter();

  const scriptUrl = import.meta.env.VITE_JANE_GPT_EMBED_URL;
  const isScriptLoaded = ref(false);
  let currentChatInstance: ChatInstance | undefined;

  function loadScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (isScriptLoaded.value) {
        resolve();
        return;
      }

      if (!scriptUrl) {
        reject(new Error('VITE_JANE_GPT_EMBED_URL is not defined in the environment'));
        return;
      }

      const script = document.createElement('script');
      script.src = scriptUrl;
      script.onload = () => {
        isScriptLoaded.value = true;
        resolve();
      };
      script.onerror = () => reject(new Error('Failed to load Jane GPT script'));
      document.body.appendChild(script);
    });
  }

  watch(
    [location, isLoading, localeWithCountry],
    ([newLocation, newIsLoading, newLocale]) => {
      if (!newIsLoading && newLocation && newLocale && newLocation.janeGptKey) {
        initializeJaneGPT(newLocation.janeGptKey, newLocale);
      }
    },
    { immediate: true },
  );

  async function initializeJaneGPT(apiKey: string, locale: string) {
    try {
      await loadScript();

      if (typeof window.janeGPT !== 'function') {
        throw new Error('janeGPT function is not available');
      }

      if (currentChatInstance) {
        currentChatInstance.destroy();
      }

      currentChatInstance = window.janeGPT({
        language: locale,
        apiKey: apiKey,
      });

      currentChatInstance.on('error', (error: Error) => {
        console.error('😥 ERROR', error);
      });

      window.chatInstance = currentChatInstance;
    } catch (error) {
      console.error('Failed to initialize Jane GPT:', error);
    }
  }
}
