import type { RouteRecordRaw } from 'vue-router';

import { getMiddleware, guardNames, navigationNames } from '@/router/router-constants';

export const customerPortalRoutes: RouteRecordRaw[] = [
  {
    path: '/customer-portal/redirect',
    children: [
      {
        name: navigationNames.redirectIdCheck,
        path: 'id-check',
        component: () => import('@/modules/customer-portal/pages/CustomerPortalRedirectIdCheckPage.vue'),
      },
    ],
  },
  {
    path: '/customer-portal',
    meta: {
      ...getMiddleware([guardNames.authenticatedGuard]),
    },
    component: () => import('@/modules/customer-portal/layouts/CustomerPortalLayout.vue'),
    children: [
      {
        path: 'account-overview',
        component: () => import('@/modules/customer-portal/pages/CustomerPortalAccountOverviewPage.vue'),
        children: [
          {
            name: navigationNames.accountOverview,
            path: ':locationId?',
            component: () => import('@/modules/customer-portal/pages/CustomerPortalAccountOverviewLocationPage.vue'),
          },
        ],
      },
      {
        name: navigationNames.customerPortalUpdatePaymentMethod,
        path: 'account-overview/:locationId/payment/update',
        component: () => import('@/modules/customer-portal/pages/CustomerPortalUpdatePaymentPage.vue'),
      },
      {
        name: navigationNames.customerPortalUpdatePaymentMethodVerify,
        path: 'account-overview/:locationId/payment/update/verify',
        component: () => import('@/modules/customer-portal/pages/CustomerPortalUpdatePaymentVerifyPage.vue'),
      },
      {
        name: navigationNames.customerPortalSharedAccess,
        path: 'account-overview/:locationId/shared-access',
        meta: {
          ...getMiddleware([guardNames.hasSharedAccess]),
        },
        component: () => import('@/modules/customer-portal/pages/CustomerPortalSharedAccessPage.vue'),
      },
    ],
    redirect: '/customer-portal/account-overview',
  },
];
