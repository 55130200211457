import { computed } from 'vue';

import { useAppStore } from '@/stores/app-store';
import { appearanceColors, type IAppearanceConfigFontVariants } from '@/types/appearance-types';
import { convertMapToRGB, mapColors } from '@/utils/appearance-utils';

export function useTenantAppearance() {
  const appStore = useAppStore();
  const data = computed(() => appStore.setupData);
  const appearance = computed(() => data.value?.appearance);
  const colorMap = computed(() => (appearance.value?.colors ? mapColors(appearance.value.colors) : undefined));
  const colorRgbMap = computed(() => convertMapToRGB(colorMap.value));
  const fonts = computed(() => appearance.value?.font);

  const style = computed(() => {
    const styles = appearanceColors
      .map((color) => {
        const rgb = colorRgbMap.value?.[color];

        if (rgb) {
          return `--color-${color}: ${rgb[0]}, ${rgb[1]}, ${rgb[2]};`;
        }

        return undefined;
      })
      .filter((value) => !!value);

    if (fonts.value) {
      styles.push(`--font-family: ${fonts.value.name}, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';`);
    }

    return styles.join('');
  });

  return {
    inlineStyles: style,
    loadFonts() {
      const root = document?.documentElement;

      if (root && fonts.value) {
        document.fonts.clear();

        const variants = fonts.value.variants;
        Object.keys(variants)
          .map((weight: string) => {
            const w = weight as keyof IAppearanceConfigFontVariants;
            if (variants?.[w] && fonts.value?.name) {
              return {
                name: fonts.value.name,
                url: variants[w],
                weight: w,
              };
            }

            return undefined;
          })
          .filter((f) => !!f && !!f.name && !!f.url && !!f.weight)
          .map((font) => {
            return new FontFace(font?.name || '', `url(${font?.url || ''})`, {
              weight: font?.weight || '400',
              style: 'normal',
            })
              .load()
              .then((loadedFont) => {
                document.fonts.add(loadedFont);
              })
              .catch(() => {
                // Ignore
              });
          });
      }
    },
  };
}
