import dayjs from 'dayjs';
import { isArray, isNumber } from 'lodash-es';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { LocationQueryValue } from 'vue-router';

import { getLocaleWithCountry, type ILanguage, languageMap } from '@/config/i18n-config';
import { useAppStore } from '@/stores/app-store';
import type { ILocationConfigItem } from '@/types';
import type { Maybe } from '@/types/utility-types';

import { formatDate, formatDateRange, formatDateTime, formatISODate } from './date-utils';

export function getFormatCurrency(
  amount: number | null | undefined,
  userLocale: ILanguage = 'en',
  currencyCode = 'EUR',
): string {
  if (amount == null) {
    return '';
  }

  const format = (languageMap[userLocale] || languageMap.en) as { locale: string };

  try {
    const formatter = new Intl.NumberFormat(format.locale, {
      style: 'currency',
      currency: currencyCode,
    });

    return formatter.format(amount);
  } catch {
    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: currencyCode,
    });

    return formatter.format(amount);
  }
}

export function formatDecimalNumber(amount: number, locale: string) {
  const formatter = new Intl.NumberFormat(locale);
  return formatter.format(amount);
}

export function formatPercentage(amount: number, userLocale: ILanguage = 'en'): string {
  const format = (languageMap[userLocale] || languageMap.en) as { locale: string };

  try {
    const formatter = new Intl.NumberFormat(format.locale, {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount / 100);
  } catch {
    // simple fallback
    const formatter = new Intl.NumberFormat('en', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount / 100);
  }
}

export function formatAddress(data: ILocationConfigItem | null | undefined): string {
  let str = '';

  if (!data) {
    return str;
  }

  if (data.street) {
    str = data.street;
    if (data.postalCode || data.city) {
      str += ',';
      if (data.postalCode) {
        str += ` ${data.postalCode}`;
      }
      if (data.city) {
        str += ` ${data.city}`;
      }
    }
  } else if (data.postalCode) {
    str = data.postalCode;
    if (data.city) {
      str += ` ${data.city}`;
    }
  } else if (data.city) {
    return data.city;
  }
  return str;
}

export type MaybeDate = string | Date | null | undefined;

export function useLocalFormatter() {
  const { locale } = useI18n();
  const store = useAppStore();

  return {
    locale,
    localeWithCountry: computed(() => {
      return getLocaleWithCountry(locale.value);
    }),
    formatDecimalNumber(amount: number) {
      return formatDecimalNumber(amount, locale.value);
    },
    formatCurrency(amount: number) {
      return getFormatCurrency(amount, locale.value as ILanguage, store.location?.currency.code);
    },
    formatPercentage(amount: number) {
      return formatPercentage(amount, locale.value as ILanguage);
    },
    formatDate(date: MaybeDate) {
      return formatDate(date, locale.value);
    },
    formatDateRange(from: MaybeDate, to?: MaybeDate) {
      return formatDateRange(from, to, locale.value);
    },
    formatDateTime(date: MaybeDate) {
      return formatDateTime(date, locale.value);
    },
    formatShortDate(date: MaybeDate) {
      return date && dayjs(date).isValid() ? dayjs(date).locale(locale.value).format('MMM D, YY') : '';
    },
    formatISODate,

    getMonthName(date: MaybeDate) {
      return dayjs(date).locale(locale.value).format('MMMM');
    },
  };
}

export function parseToInteger<TFallback = number>(
  value: Maybe<string | number | LocationQueryValue | LocationQueryValue[]>,
  fallback: TFallback,
) {
  if (isNumber(value)) {
    return value;
  }

  if (value == null) {
    return fallback;
  }

  let candidate: Maybe<string>;

  if (isArray(value)) {
    if (value.length <= 0) {
      return fallback;
    }

    candidate = value[0];
  } else {
    candidate = value;
  }

  if (!candidate) {
    return fallback;
  }

  const parsed = parseInt(candidate.trim());

  if (isNaN(parsed)) {
    return fallback;
  }

  return parsed;
}

export function parseToString<TFallback = number>(
  value: Maybe<string | LocationQueryValue | LocationQueryValue[]>,
  fallback: TFallback,
) {
  if (value == null) {
    return fallback;
  }

  let candidate: Maybe<string>;

  if (isArray(value)) {
    if (value.length <= 0) {
      return fallback;
    }

    candidate = value[0];
  } else {
    candidate = value;
  }

  if (candidate == null) {
    return fallback;
  }

  return candidate;
}
