import type { NavigationGuardWithThis } from 'vue-router';

import { navigationNames } from '@/router/router-constants';
import { useAuthStore } from '@/stores/auth-store';

export const isNotAuthenticatedGuard: NavigationGuardWithThis<unknown> = (to, from, next) => {
  const authStore = useAuthStore();

  if (authStore.isLoggedOut) {
    next();
  } else {
    next({ name: navigationNames.accountOverview });
  }
};
