<template>
  <div class="watchdog" />
</template>

<script lang="ts" setup>
import { watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { guardNames, type IAppRouteMeta, navigationNames } from '@/router/router-constants';
import { useAuthStore } from '@/stores/auth-store';

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

watchEffect(() => {
  const meta: IAppRouteMeta = route.meta as IAppRouteMeta;
  if ((meta.middleware || []).includes(guardNames.authenticatedGuard)) {
    if (authStore.isLoggedOut) {
      router.push({ name: navigationNames.login });
    }
  }
});
</script>

<style scoped lang="scss"></style>
