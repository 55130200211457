import type {
  IAuthCustomer,
  IPortalBookingItem,
  IPortalBookingSimpleItem,
  IPortalCancelBookingPayload,
  IPortalListInvoiceItem,
  IPortalLocation,
  IPortalNotificationItem,
  IPortalUpdatePaymentMethodPayload,
} from '@/types';
import type { IPaginatedResponse, IPaginationPayload, IResponse } from '@/types/api';
import { getDefaultPaginationValues } from '@/types/api';
import type { IPortalPaymentMethodItem, ISetupIntentItem } from '@/types/payment-types';
import { httpClient, publicHttpClient } from '@/utils/fetch-utils';

export const path = '/customer-portal';

export function getAllLocations(): Promise<IResponse<IPortalLocation[]>> {
  return httpClient.get(`${path}/location`).then((response) => response.data);
}

export function getBookings(options: { locationId: number; pagination?: IPaginationPayload }) {
  const { locationId, pagination } = options;

  return httpClient
    .get<IPaginatedResponse<IPortalBookingItem[]>>(`${path}/booking/${locationId}`, {
      params: {
        ...(pagination || getDefaultPaginationValues()),
      },
    })
    .then((response) => response.data);
}

export function getAllBookings(options: { locationId: number }) {
  const { locationId } = options;

  return httpClient
    .get<IResponse<IPortalBookingSimpleItem[]>>(`${path}/booking/${locationId}/payment-method`)
    .then((response) => response.data);
}

export function updatePaymentMethodBookings(options: {
  locationId: number;
  payload: IPortalUpdatePaymentMethodPayload;
}) {
  const { locationId, payload } = options;

  return httpClient
    .put<IResponse<IPortalBookingItem>>(`${path}/booking/${locationId}/payment-method`, payload)
    .then((response) => response.data);
}

export function cancelBooking(bookingId: number, payload: IPortalCancelBookingPayload) {
  return httpClient
    .put<IResponse<IPortalBookingItem>>(`${path}/booking/${bookingId}/cancel`, payload)
    .then((response) => response.data);
}

export function getInvoiceHistory(options: { locationId: number; pagination?: IPaginationPayload }) {
  const { locationId, pagination } = options;

  return httpClient
    .get<IPaginatedResponse<IPortalListInvoiceItem[]>>(`${path}/invoice/${locationId}`, {
      params: { ...(pagination || getDefaultPaginationValues()) },
    })
    .then((response) => response.data);
}

export function getOpenInvoices(options: { locationId: number; pagination?: IPaginationPayload }) {
  const { locationId, pagination } = options;

  return httpClient
    .get<IPaginatedResponse<IPortalListInvoiceItem[]>>(`${path}/invoice/${locationId}`, {
      params: {
        ...(pagination || getDefaultPaginationValues()),
        openOnly: true,
      },
    })
    .then((response) => response.data);
}

export function getPaymentMethods(locationId: number) {
  return httpClient
    .get<IResponse<IPortalPaymentMethodItem[]>>(`${path}/customer/${locationId}/payment-method`)
    .then((response) => response.data);
}

export function getSetupIntent(locationId: number) {
  return httpClient
    .get<IResponse<ISetupIntentItem>>(`${path}/customer/${locationId}/setup-intent`)
    .then((response) => response.data);
}

export function getNotifications(locationId: number) {
  return httpClient
    .get<IResponse<IPortalNotificationItem[]>>(`${path}/notification`, { params: { location_id: locationId } })
    .then((response) => response.data);
}

export function getLoggedInCustomerInformation() {
  // using public http client here. This api call is also used in the booking portal.
  // It must not redirect to the login there if it fails due to an unauthorized response
  return publicHttpClient.get<IResponse<IAuthCustomer>>(`${path}/auth/customer`).then((d) => d.data);
}
