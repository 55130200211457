<template>
  <BaseAppearanceRoot data-test-id="app">
    <template v-if="!isLoading">
      <RouterView />
      <CookieConsent />
    </template>
    <template v-else>
      <UISkeletonSPA />
    </template>
  </BaseAppearanceRoot>
  <AuthWatchdog />
  <Notifications
    width="300px"
    position="bottom center"
    :speed="250"
  />
</template>

<script async lang="ts" setup>
import { registerHiddenFeatures } from '@/composables/use-hidden-feature';
import { useI18nLocale } from '@/composables/use-i18n-locale';
import { useJaneGPTInitializer } from '@/composables/use-jane-init';
import { usePageTitle } from '@/composables/use-page-title';
import { useRemoteTranslations } from '@/composables/use-remote-translations';
import { useSetupData } from '@/composables/use-setup-data';
import { useVeeValidate } from '@/composables/use-vee-validate';
import AuthWatchdog from '@/modules/auth/components/AuthWatchdog.vue';
import BaseAppearanceRoot from '@/modules/base/components/BaseAppearanceRoot.vue';
import CookieConsent from '@/modules/cookie/components/CookieConsent.vue';
import UISkeletonSPA from '@/modules/ui-skeletons/components/UISkeletonSPA.vue';
import { useAuthStore } from '@/stores/auth-store';

registerHiddenFeatures();
useAuthStore();
useSetupData();
usePageTitle();
useVeeValidate();
useI18nLocale();
useJaneGPTInitializer();

const { isLoading } = useRemoteTranslations();
</script>

<style scoped></style>
