import { computed } from 'vue';

import type { ICookieConsentSelection } from '@/modules/cookie/types/cookie-types';
import { useConsentStore } from '@/stores/consent-store';

export function useCookieConsent() {
  const store = useConsentStore();

  function setCookies(options: ICookieConsentSelection) {
    store.setCookies(options);
  }

  function deleteCookies() {
    store.deleteCookies();
  }

  return {
    setCookies,
    deleteCookies,
    hasMarketingCookies: computed(() => store.hasMarketingCookies),
    hasNecessaryCookies: computed(() => store.hasNecessaryCookies),
    hasSeenCookieConsent: computed(() => store.hasSeenCookieConsent),
  };
}
